<template>
  <BaseCard class="maxWidth">
    <span>
      <TabMenu :model="items" :active-index="activeIndex" />
      <router-view v-slot="{ Component }">
        <transition mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </span>
  </BaseCard>
</template>

<script setup lang="ts">
import { ref } from "vue";
import BaseCard from "@/ui/BaseCard.vue";
import TabMenu from "primevue/tabmenu";
import { useRouter } from "vue-router";

const router = useRouter();
const activeIndex = ref(router.currentRoute.value.path === "/absence/calendar" ? 1 : 0);
const items = [
  { label: "Meine", icon: "pi pi-fw pi-list", command: () => router.push("/absence") },
  { label: "Alle (Kalender)", icon: "pi pi-fw pi-calendar", command: () => router.push("/absence/calendar") },
];
</script>

<style scoped>
.v-enter-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
