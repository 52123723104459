import moment from "moment";
import "moment-duration-format";
import { CalendarPublicHoliday, PublicHoliday } from "@/data-types";
import { nextTick } from "vue";

function getFocusableHtmlElements(query: string, activeElem: HTMLElement, selector?: string): HTMLElement[] {
  const elementParent = (selector && activeElem.closest(selector)) || document;
  return Array.from(elementParent.querySelectorAll<HTMLElement>(query));
}

function getSortedFocusableHtmlElementsByTabIndex(focusableHtmlElements: HTMLElement[]) {
  return focusableHtmlElements
    .slice()
    .filter((elem) => elem.tabIndex > 0)
    .sort((a, b) => a.tabIndex - b.tabIndex);
}

function concatHtmlElements(sortedFocusableHtmlElementsByTabIndex: HTMLElement[], focusableHtmlElements: HTMLElement[]) {
  return sortedFocusableHtmlElementsByTabIndex.slice().concat(focusableHtmlElements.filter((elem) => elem.tabIndex == 0));
}

function getQueryString(query: string | undefined) {
  return (
    query ??
    [
      'a[href]:not([disabled]):not([type="hidden"]):not([tabindex="-1"])',
      'area[href]:not([disabled]):not([type="hidden"]):not([tabindex="-1"])',
      'textarea:not([disabled]):not([type="hidden"]):not([tabindex="-1"])',
      'button:not([disabled]):not([type="hidden"]):not([tabindex="-1"])',
      'input:not([disabled]):not([type="hidden"]):not([tabindex="-1"])',
      'select:not([disabled]):not([type="hidden"]):not([tabindex="-1"])',
      '[tabindex]:not([disabled]):not([tabindex="-1"])',
    ].join(",")
  );
}

export function focusNextHtmlElementFromList(activeElementIndex: number, htmlElements: HTMLElement[]) {
  if (activeElementIndex <= -1) return;
  const nextElement = htmlElements[activeElementIndex + 1] || htmlElements[0];
  nextElement.focus();
}

export function focusPreviousHtmlElementFromList(activeElementIndex: number, htmlElements: HTMLElement[]) {
  if (activeElementIndex <= -1) return;
  const previousElement = htmlElements[activeElementIndex - 1] || htmlElements[htmlElements.length - 1];
  previousElement.focus();
}

export async function focusNextElement(
  focusHtmlElementAction = focusNextHtmlElementFromList,
  query?: string,
  selector?: string,
  activeElem?: HTMLElement | null,
) {
  activeElem = activeElem ?? (document.activeElement as HTMLElement | null);
  if (!activeElem) return;

  await nextTick(() => activeElem.blur());
  const queryString = getQueryString(query);
  const focusableHtmlElements = getFocusableHtmlElements(queryString, activeElem, selector);
  const sortedFocusableHtmlElementsByTabIndex = getSortedFocusableHtmlElementsByTabIndex(focusableHtmlElements);
  const htmlElements = concatHtmlElements(sortedFocusableHtmlElementsByTabIndex, focusableHtmlElements);
  const activeElementIndex = htmlElements.indexOf(activeElem);
  focusHtmlElementAction(activeElementIndex, htmlElements);
}

export function splitTimeString(timeText: string | undefined) {
  if (!timeText) return { hours: 0, minutes: 0 };
  const [hours, minutes] = timeText.split(":");
  return {
    hours: parseInt(hours),
    minutes: parseInt(minutes),
  };
}

export function buildTimeString(hours: number, minutes: number) {
  return buildTimeStringFromHours(hours + minutes / 60);
}

export function buildTimeStringFromHours(hours: number | null) {
  if (hours == null) return "--:--";
  return moment.duration(hours, "hours").format("*hh:mm");
}

export function roundTime(timeStr: string) {
  if (!timeStr) return timeStr;

  const segs = splitTimeString(timeStr);
  const off = segs.minutes % 3;
  if (off != 0) {
    if (off == 1) {
      segs.minutes--;
    } else {
      segs.minutes++;
    }
    return buildTimeString(segs.hours, segs.minutes);
  }
  return timeStr;
}

export function formatDateToERP(date: Date): string {
  return moment(date).format("YYYY-MM-DD");
}

export function formatDateStringToGerman(dateString: string): string {
  if (dateString === undefined) {
    return "";
  } else {
    const date = new Date(dateString);
    return moment(date).format("DD.MM.YYYY");
  }
}

export function getDayFromDate(date: Date): number {
  return parseInt(moment(date).format("D"));
}

export function getMonthFromDate(date: Date): string {
  return moment(date).format("MM");
}

export function getYearFromDate(date: Date): string {
  return moment(date).format("YYYY");
}

export function formatDateWithoutYear(date: Date | undefined, locales?: string) {
  return (
    date?.toLocaleDateString(locales, {
      month: "2-digit",
      day: "2-digit",
    }) ?? "xx.xx."
  );
}

export function getWorkdayForDate(date: Date) {
  switch (date?.getDay()) {
    case 1:
      return "Mo";
    case 2:
      return "Di";
    case 3:
      return "Mi";
    case 4:
      return "Do";
    case 5:
      return "Fr";
    case 6:
      return "Sa";
    case 0:
      return "So";
  }
  return "--";
}

export function startOfMonth(date: Date) {
  return moment(date).startOf("month").toDate();
}

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export function convertFromErpDate(day: string | null) {
  const segs = day?.split("-") ?? ["--", "--", "--"];
  return segs[2] + "." + segs[1] + "." + segs[0];
}

// Absence.ts in utils
export function sortPublicHolidays(publicHolidays: PublicHoliday[]) {
  const result: CalendarPublicHoliday[] = [];
  publicHolidays.forEach((publicHoliday) => {
    const holidayDate = new Date(publicHoliday.date);
    result.push({
      name: publicHoliday.name,
      day: getDayFromDate(holidayDate),
    });
  });
  return result;
}
