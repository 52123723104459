<template>
  <div class="p-mt-5">
    <ProfileForm :is-changed="hasChanges" @save="save(!v$.$invalid)">
      <ProfileFormField
        v-if="!isExternal"
        label="Geburtstag für andere anzeigen"
        type="switch"
        width="6"
        identifier="show_birthdays"
        :submitted="submitted"
        :is-changed="hasChanges"
      />
      <ProfileFormField
        v-if="!isExternal"
        label="Im Kalender angezeigt werden"
        type="switch"
        width="6"
        identifier="show_absences"
        :submitted="submitted"
        :is-changed="hasChanges"
      />
      <ProfileFormField
        v-if="!isExternal"
        label="Anzeigen, dass ich im Büro bin"
        type="switch"
        width="6"
        identifier="show_presences"
        :submitted="submitted"
        :is-changed="hasChanges"
      />
      <ProfileFormField
        label="Endzeit automatisch berechnen"
        type="switch"
        width="6"
        identifier="calculate_end_of_working_day"
        :submitted="submitted"
        :is-changed="hasChanges"
      />
    </ProfileForm>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, inject, provide, ref } from "vue";
import ProfileForm from "@/features/profile/components/ProfileForm.vue";
import ProfileFormField from "@/features/profile/components/ProfileFormField.vue";
import { useVuelidate } from "@vuelidate/core";
import { loading, validate } from "@/keys";
import erpnextApi from "@/rest/ErpnextApi.ts";
import { useToast } from "primevue/usetoast";
import { erpNextUserProfile } from "@/data-types";
import { useStore } from "vuex";
import clonedeep from "lodash.clonedeep";
import { showErrorToast, showSuccessToast } from "@/utils/Toast";
import { initDefaultErpNextUserProfile } from "@/features/profile/utils/Profile";

export default defineComponent({
  name: "ProfileOptions",
  components: { ProfileForm, ProfileFormField },
  setup() {
    const store = useStore();
    const toast = useToast();
    const submitted = ref(false);
    const userProfile = ref<erpNextUserProfile>(initDefaultErpNextUserProfile());
    const isLoading = inject(loading, ref(true));
    const isExternal = computed(() => store.getters.isExternal);

    isLoading.value = true;
    userProfile.value = clonedeep(store.getters.getUserProfile);

    (async () => {
      try {
        // then() and catch() are not working in dispatch, so async/await is used
        await store.dispatch("fetchTransportationProfiles");
      } catch (err) {
        showErrorToast(toast, `${err}`);
      } finally {
        isLoading.value = false;
      }
    })();

    const initialState = ref(clonedeep(userProfile.value));

    const hasChanges = computed(() => {
      return JSON.stringify(userProfile.value) !== JSON.stringify(initialState.value);
    });

    const rules = {
      show_birthdays: {},
      show_absences: {},
      show_presences: {},
      calculate_end_of_working_day: {},
      default_transportation: {},
    };
    const v$ = useVuelidate(rules, userProfile);
    provide(validate, v$);

    function save(isFormValid: boolean) {
      submitted.value = true;
      if (!isFormValid) return;
      isLoading.value = true;
      erpnextApi
        .updateProfileSettings(userProfile.value)
        .then(() => {
          showSuccessToast(toast, erpnextApi.ProfileUpdatedMessage);
          store.commit("setUserProfile", userProfile.value);
        })
        .catch((err) => showErrorToast(toast, err))
        .finally(() => (isLoading.value = false));
      initialState.value = clonedeep(userProfile.value);
    }

    return {
      v$,
      submitted,
      save,
      isExternal,
      hasChanges,
    };
  },
});
</script>

<style scoped></style>
