<template>
  <div class="day" :class="childClass">
    <div :class="todayLine && 'todayLine'">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  childClass?: string;
  todayLine?: boolean;
}
withDefaults(defineProps<Props>(), {
  childClass: "",
  todayLine: false,
});
</script>

<style scoped>
.day {
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
  width: 100%;
  height: 2.5rem;
  background-color: transparent;
}

.transparent {
  height: 0;
}

.numbersOnly {
  margin-top: 0.5rem;
  height: 1rem;
}

.dateNames {
  height: 1.5rem;
  background-color: var(--calendar-work-day);
  margin-bottom: -0.1rem;
}

.weekday {
  background-color: var(--calendar-work-day);
}

.absenceDates {
  background-color: var(--inclusive-sky-blue);
}

.offsiteDates {
  background-color: var(--inclusive-vermilion);
}

.weekend {
  background-color: var(--calendar-weekend);
}

.publicHoliday {
  background-color: var(--incluvie-reddish-purple);
}

.absenceSplitPublicHoliday {
  background-image: -webkit-linear-gradient(90deg, var(--incluvie-reddish-purple) 50%, var(--inclusive-sky-blue) 50%);
}

.dayOff {
  background-color: var(--inclusive-orange);
}

.absenceSplitWeekend {
  background-image: -webkit-linear-gradient(90deg, var(--calendar-weekend) 50%, var(--inclusive-sky-blue) 50%);
}

.todayLine {
  border: 0.2rem solid var(--inclusive-bluish-green);
  margin-left: auto;
  margin-right: auto;
}
</style>
