export function createTimeStringFromDate(date: Date | undefined) {
  if (!date) return { hours: 0, minutes: 0 };
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const current_time = hours + ":" + minutes;
  return current_time; // Format: z.B 12:32
}

export function getTodaysDateFormatted() {
  //Das Format welches returned wird ist z.B. Mittwoch 13.04.2023
  const currentDate = new Date();
  const weekdays = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];
  const day = String(currentDate.getDate()).padStart(2, "0");
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const year = currentDate.getFullYear();
  const weekday = weekdays[currentDate.getDay()];
  return `${weekday} ${day}.${month}.${year}`;
}
