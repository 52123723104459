<template>
  <h2 class="p-ml-1 p-mb-2 full-width">
    {{ label }}
  </h2>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ProfileFormHeader",
  props: {
    label: { type: String, required: false, default: "" },
  },
});
</script>

<style scoped></style>
