import { ChartNode, Employee, erpNextOrganisationChart, Person } from "@/data-types";
import erpnextApi from "@/rest/ErpnextApi.ts";
import store from "@/store";

enum nodeLevel {
  MANAGER = 1,
  TEAM_LEADER = 2,
  STAFF_POSITION = 3,
  PROJECTS = 4,
  ADMINISTRATION = 5,
}

export enum roles {
  MANAGER = "Geschäftsführer",
  ADMINISTRATION = "Verwaltung",
  STAFF_POSITION = "Stabsstellen",
  PROJECTS = "Projekte",
  TEAM_LEADER = "Teamleiter",
  PROJECT_LEADER = "project_lead",
}

export default async function getErpOrganisationChart() {
  const idCounter = 6;
  const nodes: ChartNode[] = [];
  const employees: Map<string, Employee> = store.getters.getAllEmployeesAsMap;
  const erpOrganisationChart = await erpnextApi.getOrganisationChart();

  /* Organisation data available? */
  isOrganisationDataAvailable(erpOrganisationChart);

  /* Calculate top-level node for managers and add to chart */
  createManagerNode(erpOrganisationChart, nodes, employees);

  /* Add staff units and add to chart  */
  createStaffUnitsNodes(nodes, erpOrganisationChart, idCounter, employees);

  /* Add administration and add to chart  */
  createAdministrationNode(erpOrganisationChart, nodes, employees);

  /* Add team leader and add to chart  */
  createTeamLeaderNode(erpOrganisationChart, nodes, employees);

  /* Add projects and add to chart  */
  createProjectNodes(nodes, erpOrganisationChart, idCounter, employees);

  return nodes;
}

/**
 * Check if backend delivers organisation data
 *
 * @param erpOrganisationChart
 */
function isOrganisationDataAvailable(erpOrganisationChart: erpNextOrganisationChart) {
  if (erpOrganisationChart.staff_units.length === 0) {
    throw new Error("Keine Organisationsdaten vorhanden");
  }
}

/**
 * Create top-level node for managers
 *
 * @param erpOrganisationChart
 * @param nodes
 * @param employees
 */
function createManagerNode(erpOrganisationChart: erpNextOrganisationChart, nodes: ChartNode[], employees: Map<string, Employee>) {
  const managers: Person[] = erpOrganisationChart.staff_units.filter((person) => person.role === roles.MANAGER) ?? [];
  if (managers.length === 0) {
    throw new Error("Keine Geschäftsführer vorhanden.");
  }
  nodes.push(createNode(nodeLevel.MANAGER, null, roles.MANAGER, getNames(employees, managers, false), calcHeight(0)));
}

/**
 * Create staff unit node and sub-nodes
 *
 * @param nodes
 * @param erpOrganisationChart
 * @param idCounter
 * @param employees
 */
function createStaffUnitsNodes(nodes: ChartNode[], erpOrganisationChart: erpNextOrganisationChart, idCounter: number, employees: Map<string, Employee>) {
  nodes.push(createNode(nodeLevel.STAFF_POSITION, nodeLevel.MANAGER, roles.STAFF_POSITION, "", calcHeight(0)));
  const staffUnits: string[] = [...new Set(erpOrganisationChart.staff_units.map((p) => p.role))];
  staffUnits.forEach((unit) => {
    if (unit !== roles.MANAGER && unit !== roles.ADMINISTRATION && unit !== roles.TEAM_LEADER) {
      const persons: Person[] = erpOrganisationChart.staff_units.filter((person) => person.role === unit);
      nodes.push(createNode(idCounter++, nodeLevel.STAFF_POSITION, unit, getNames(employees, persons, true), calcHeight(persons.length)));
    }
  });
  return idCounter;
}

/**
 * Create administration node and members
 *
 * @param erpOrganisationChart
 * @param nodes
 * @param employees
 */
function createAdministrationNode(erpOrganisationChart: erpNextOrganisationChart, nodes: ChartNode[], employees: Map<string, Employee>) {
  const administrations: Person[] = erpOrganisationChart.staff_units.filter((person) => person.role === roles.ADMINISTRATION);
  nodes.push(
    createNode(
      nodeLevel.ADMINISTRATION,
      nodeLevel.MANAGER,
      roles.ADMINISTRATION,
      getNames(employees, administrations, true),
      calcHeight(administrations.length),
    ),
  );
}

/**
 * Create team-leader node and members
 *
 * @param erpOrganisationChart
 * @param nodes
 * @param employees
 */
function createTeamLeaderNode(erpOrganisationChart: erpNextOrganisationChart, nodes: ChartNode[], employees: Map<string, Employee>) {
  const teamLeaders: Person[] = erpOrganisationChart.staff_units.filter((person) => person.role === roles.TEAM_LEADER);
  if (teamLeaders.length === 0) return;
  nodes.push(createNode(nodeLevel.TEAM_LEADER, nodeLevel.MANAGER, roles.TEAM_LEADER, getNames(employees, teamLeaders, false), calcHeight(teamLeaders.length)));
}

/**
 * Create project nodes and sub-nodes
 *
 * @param nodes
 * @param erpOrganisationChart
 * @param idCounter
 * @param employees
 */
function createProjectNodes(nodes: ChartNode[], erpOrganisationChart: erpNextOrganisationChart, idCounter: number, employees: Map<string, Employee>) {
  nodes.push(createNode(nodeLevel.PROJECTS, nodeLevel.MANAGER, roles.PROJECTS, "", calcHeight(0)));
  erpOrganisationChart.projects.forEach((project) => {
    const members: Person[] = project.members;
    nodes.push(createNode(idCounter++, nodeLevel.PROJECTS, project.project_name, getNames(employees, members, true), calcHeight(members.length)));
  });
  return idCounter;
}

function createNode(id: number, parentId: number | null, positionName: string, name: string, height: number) {
  return {
    id: id,
    parentId: parentId,
    positionName: positionName,
    name: name,
    height: height,
  };
}

/* If there are more than 3 members, add 14px for each member */
function calcHeight(lines: number) {
  return 70 + (lines > 3 ? (lines - 3) * 14 : 0);
}

function getNames(employees: Map<string, Employee>, persons: Person[], extensionRelevant: boolean) {
  return persons
    .map((person) => {
      const employee = employees.get(person.employee);
      const external = employee?.is_external ? " (Ext.)" : "";
      const title = person.substitution && extensionRelevant ? " (Stv.)" : "";
      const lead = person.role === roles.PROJECT_LEADER && extensionRelevant ? " (PV.)" : "";

      const name = employee?.first_name + " " + employee?.last_name + external + lead + title;
      return name.length <= 30 ? name : name.substring(0, 27) + "...";
    })
    .join("<br>");
}
