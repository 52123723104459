import { reactive, Reactive } from "vue";

export interface NumberInputReactiveObject {
  timeValue: string | null | undefined;
  min: number;
  max: number;
  countedInputs: 0 | 1 | 2;
  changed: boolean;
}

export function useDesktopTimePicker() {
  const hourInput: Reactive<NumberInputReactiveObject> = reactive<NumberInputReactiveObject>({
    timeValue: undefined,
    min: 0,
    max: 23,
    countedInputs: 0,
    changed: false,
  });

  const minuteInput: Reactive<NumberInputReactiveObject> = reactive<NumberInputReactiveObject>({
    timeValue: undefined,
    min: 0,
    max: 59,
    countedInputs: 0,
    changed: false,
  });

  function hasTimeInputChanged() {
    return hourInput.changed || minuteInput.changed;
  }

  function generateTwoDigitNumberString(inputValue: number | undefined): string {
    if (inputValue == undefined) return "";
    return inputValue.toString().padStart(2, "0");
  }

  function setHasChanged(hasChanged: boolean) {
    hourInput.changed = hasChanged;
    minuteInput.changed = hasChanged;
  }

  function setHourAndMinuteToTimeString(time: string | undefined): void {
    if (!time) {
      hourInput.timeValue = minuteInput.timeValue = null;
      return;
    }
    const [hours, minutes] = time.split(":").map((part) => (part ? part : null));
    hourInput.timeValue = hours != null && !isNumberInRange(hours, hourInput.max) ? "00" : hours;
    minuteInput.timeValue = minutes != null && !isNumberInRange(minutes, minuteInput.max) ? "00" : minutes;
  }

  function isNumberInRange(num: string | undefined | null, maxValue: number, minValue = 0): boolean {
    const parsedNum = num ? parseInt(num) : null;
    return parsedNum != null && parsedNum <= maxValue && parsedNum >= minValue;
  }

  function resetTimeInput(timeInput: NumberInputReactiveObject) {
    timeInput.timeValue = "00";
  }

  function incTimeInput(timeInput: NumberInputReactiveObject, max: number): void {
    resetTimePickerCounter();
    if (timeInput.timeValue == undefined) timeInput.timeValue = "00";
    let parsedTimeValue = parseInt(timeInput.timeValue);
    if (parsedTimeValue < max) {
      parsedTimeValue++;
      timeInput.timeValue = generateTwoDigitNumberString(parsedTimeValue);
      setHasChanged(true);
    }
  }

  function decTimeInput(timeInput: NumberInputReactiveObject): void {
    resetTimePickerCounter();
    if (timeInput.timeValue == undefined) timeInput.timeValue = "00";
    const minTime = 0;
    let parsedTimeValue = parseInt(timeInput.timeValue);
    if (parsedTimeValue > minTime) {
      parsedTimeValue--;
      timeInput.timeValue = generateTwoDigitNumberString(parsedTimeValue);
      setHasChanged(true);
    }
  }

  function preventSpecificKeyInput(event: KeyboardEvent): void {
    const allowedKeys = ["shift", "tab"];
    const isAllowedKey = allowedKeys.includes(event.key.toLowerCase());
    if (isAllowedKey) return;
    const isOnlyNumber = hasOnlyNumbersInString(event.key);
    if (!isOnlyNumber) event.preventDefault();
  }

  function hasOnlyNumbersInString(s: string): boolean {
    const regex = /[0-9]/;
    return regex.test(s);
  }

  function shouldJumpToNextInput(timeInput: NumberInputReactiveObject): boolean {
    const { timeValue, countedInputs, max } = timeInput;
    if (timeValue == undefined) return false;
    const parsedTimeValue = parseInt(timeValue);

    if (parsedTimeValue > 9) return true;

    const firstDigitOfMaxTime = Math.floor(max / 10);
    if (parsedTimeValue > firstDigitOfMaxTime) return true;

    return countedInputs > 1;
  }

  function getLastDigitOfNumber(num: string): string {
    const parsedNum = parseInt(num);
    return (parsedNum % 10).toString();
  }

  function resetTimePickerCounter() {
    hourInput.countedInputs = 0;
    minuteInput.countedInputs = 0;
  }

  function parseTimeValue(timeValue: string | undefined | null): number {
    return parseInt(timeValue ?? "00");
  }

  function concatenateNewValue(previousTimeValue: number, insertedNumber: string | null): string {
    return `${previousTimeValue}${insertedNumber}`;
  }

  function processTimeInput(timeInput: NumberInputReactiveObject, newUnformattedTimeValue: string): void {
    const isInValidRange = isNumberInRange(newUnformattedTimeValue, timeInput.max);

    if (!isInValidRange || (isInValidRange && timeInput.countedInputs < 2)) newUnformattedTimeValue = getLastDigitOfNumber(newUnformattedTimeValue);

    timeInput.timeValue = generateTwoDigitNumberString(parseInt(newUnformattedTimeValue));
  }

  return {
    hourInput,
    minuteInput,
    setHasChanged,
    setHourAndMinuteToTimeString,
    resetTimeInput,
    hasTimeInputChanged,
    incTimeInput,
    decTimeInput,
    preventSpecificKeyInput,
    shouldJumpToNextInput,
    resetTimePickerCounter,
    parseTimeValue,
    concatenateNewValue,
    processTimeInput,
  };
}
