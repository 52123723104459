<template>
  <div class="p-d-flex p-flex-column p-flex-md-row p-jc-center">
    <div class="p-d-flex p-ai-center p-jc-center">
      <h2>{{ title }}</h2>
    </div>
    <div class="p-d-flex p-ai-center p-jc-center p-pl-0 p-pl-md-5">
      <Button icon="pi pi-angle-double-left" class="primaryButton" style="width: 4rem" :disabled="disablePreviousMonth" @click="onChangeMonthBackward" />
      <Calendar id="calendarDate" v-model="currentDate" class="p-mx-3 myCalendar" view="month" input-class="p-text-center" date-format="mm/yy" />
      <Button icon="pi pi-angle-double-right" class="primaryButton" style="width: 4rem" :disabled="disableNextMonth" @click="onChangeMonthForward" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, toRef, watch } from "vue";
import Button from "primevue/button";
import Calendar from "primevue/calendar";

interface Props {
  disablePreviousMonth?: boolean;
  disableNextMonth?: boolean;
  title?: string;
  currentDateRo: Date;
}

const props = withDefaults(defineProps<Props>(), {
  disablePreviousMonth: true,
  disableNextMonth: true,
  title: "",
});

const emit = defineEmits<{
  changeDate: [value: Date];
  changeMonthBackward: [];
  changeMonthForward: [];
}>();

const currentDateRo = toRef(props, "currentDateRo");
const currentDate = ref(new Date());
watch(currentDate, (value) => {
  emit("changeDate", value);
});
watch(currentDateRo, () => {
  currentDate.value = currentDateRo.value;
});

function onChangeMonthBackward() {
  emit("changeMonthBackward");
}

function onChangeMonthForward() {
  emit("changeMonthForward");
}
</script>

<style scoped></style>
