import { Employee } from "@/data-types";
import erpnextApi from "@/rest/ErpnextApi.ts";
import { RootState } from "@/store";
import { Module } from "vuex";

export interface EmployeeStoreState {
  allEmployees: Map<string, Employee>;
  favoriteEmployees: Map<string, string[]>;
}

const employeeStore: Module<EmployeeStoreState, RootState> = {
  state: () => ({
    allEmployees: new Map<string, Employee>(),
    favoriteEmployees: new Map<string, string[]>(),
  }),
  getters: {
    getAllEmployeesAsMap(state: EmployeeStoreState) {
      return state.allEmployees;
    },
    getAllEmployees(state: EmployeeStoreState): Employee[] {
      return Array.from(state.allEmployees.values()).sort((x: any, y: any) => x.last_name.localeCompare(y.last_name)) as Employee[];
    },
    getFavoriteEmployees(state: EmployeeStoreState) {
      return state.favoriteEmployees;
    },
  },
  mutations: {
    setAllEmployees(state: EmployeeStoreState, employees: Employee[]) {
      for (const employee of employees) {
        employee.full_name = employee.first_name + " " + employee.last_name;
        state.allEmployees.set(employee.name, employee);
      }
    },
    setFavoriteEmployees(state: EmployeeStoreState, favoriteEmployees: Map<string, string[]>) {
      state.favoriteEmployees = favoriteEmployees;
    },
  },
  actions: {
    async fetchAllEmployees(context: any) {
      if (context.state.allEmployees.size === 0) {
        const allEmployees: Employee[] = await erpnextApi.getEmployees();
        context.commit("setAllEmployees", allEmployees);
      }
    },
    async fetchFavoriteEmployees(context: any) {
      if (context.state.favoriteEmployees.size === 0) {
        context.commit("setFavoriteEmployees", await erpnextApi.getFavoriteEmployeeGroups());
      }
    },
    async updateFavoriteEmployees(context: any, commit: any) {
      const favoriteEmployees = context.state.favoriteEmployees;
      favoriteEmployees.set(commit.group, commit.favorites);
      context.commit("setFavoriteEmployees", favoriteEmployees);
    },
  },
};

export default employeeStore;
