export interface ErpNextPostResponse {
  message: {
    Message?: string;
    Result: boolean;
  };
}

export interface Credentials {
  username: string;
  password: string;
}

export interface WeekDay {
  displayDate: string;
  currentDate: Date;
  name: string;
  v: string;
}

export interface BookingPositionDescription {
  name: string;
  closed: boolean;
  project: string;
  description: string;
  total_hours: number;
  used_hours: number;
  left_hours: number;
  requires_comment: boolean;
  has_comment: boolean;
  changeable_from: Date | null;
  changeable_till: Date | null;

  // Additional attributes
  number: string;
  full_name: string;
  missing: boolean;
  isUserAllowedToBook?: boolean;
}

export interface BookingDayPosition {
  name: string;
  hours: string;
  comment?: string;
}

export interface BookingDay {
  currentDate: string;
  date?: Date;
  start?: string;
  end?: string;
  break?: string;
  state: string;
  totalWorkHours: number;
  positionHours: BookingDayPosition[];
  publicHoliday?: string;
  booked: boolean;
  error?: string;
  fixed?: string;
  mood?: number;
  notes?: string;
}

export interface MandatoryDetail {
  work_plan: string;
  start_date: string;
  end_date: string;
  hours: number;
  percent: number;
}

export interface Payouts {
  amount: number;
  comment: string;
  date: string;
  hours: number;
}

export interface AnnualStatement {
  annual: number;
  last_update: string;
  carry_hours: number;
  current_hours: number;
  sick_hours: number;
  sick_days: number;
  payout_hours: number;
  mandatory_hours: number;
  carry_normal_hours: number;
  carry_overtime_hours: number;
  current_normal_hours: number;
  current_overtime_hours: number;
  guessed_overtime_hours: number;
  lump_sum_correction_hours: number;
  amount: string;
  work_plan_monday?: number;
  work_plan_tuesday?: number;
  work_plan_wednesday?: number;
  work_plan_thursday?: number;
  work_plan_friday?: number;
  work_plan_saturday?: number;
  work_plan_sunday?: number;
  left_hours: number;
  work_days_at_office?: number;
  work_days?: number;
  mandatory_details: MandatoryDetail[];
  payouts: Payouts[];
  work_plan_mode: string;
  work_plan_age: string;

  [key: string]: any;
}

export interface AnnualHours {
  annual: number;
  last_update: string;
  carry_hours: number;
  current_hours: number;
  sick_hours: number;
  sick_days: number;
  payout_hours: number;
  mandatory_hours: number;
  carry_normal_hours: number;
  carry_overtime_hours: number;
  current_normal_hours: number;
  current_overtime_hours: number;
  guessed_overtime_hours: number;
  amount: string;
  work_plan_monday: number;
  work_plan_tuesday: number;
  work_plan_wednesday: number;
  work_plan_thursday: number;
  work_plan_friday: number;
  work_plan_saturday: number;
  work_plan_sunday: number;
  left_hours: number;
  mandatory_details: MandatoryDetail[];
  payouts: Payouts[];
}

export interface YearReportBookingPosition {
  name: string;
  project?: string;
  months: number[];
}

export interface YearReport {
  year: string;
  booking_positions: YearReportBookingPosition[];
}

export interface BookingPosition {
  name: string;
  description: string;
  project_name: string;
}

export const emptyYearReport: YearReport = { booking_positions: [], year: "" };

export const emptyAnnualStatement: AnnualStatement = {
  annual: 0,
  last_update: "",
  carry_hours: 0,
  current_hours: 0,
  sick_hours: 0,
  sick_days: 0,
  payout_hours: 0,
  mandatory_hours: 0,
  carry_normal_hours: 0,
  carry_overtime_hours: 0,
  current_normal_hours: 0,
  current_overtime_hours: 0,
  guessed_overtime_hours: 0,
  lump_sum_correction_hours: 0,
  amount: "",
  work_plan_monday: 0,
  work_plan_tuesday: 0,
  work_plan_wednesday: 0,
  work_plan_thursday: 0,
  work_plan_friday: 0,
  work_plan_saturday: 0,
  work_plan_sunday: 0,
  work_days_at_office: 0,
  work_days: 0,
  left_hours: 0,
  mandatory_details: [],
  payouts: [],
  work_plan_mode: "",
  work_plan_age: "",
};
export const emptyBookingPositionsDescription: BookingPositionDescription = {
  name: "",
  closed: false,
  project: "",
  description: "",
  total_hours: 0,
  used_hours: 0,
  left_hours: 0,
  requires_comment: false,
  has_comment: false,
  changeable_from: null,
  changeable_till: null,

  // Additional attributes
  number: "",
  full_name: "",
  missing: false,
};

export interface PublicHoliday {
  name: string;
  date: string;
}

export const weekend: string[] = ["Samstag", "Sonntag"];

export interface CalendarPublicHoliday {
  name: string;
  day: number;
}

export interface AbsenceListItem {
  type: string;
  days: number;
  from: string;
  to: string;
}

export interface LeaveType {
  name: string;
  allocated?: number;
  taken?: number;
  allocated_next_year?: number;
  taken_next_year?: number;
}

export interface HolidayRequest {
  name?: string;
  creation?: string;
  status: string;
  employee_name?: string;
  from_date: string;
  to_date: string;
  leave_type: string;
  total_leave_days: number;
  can_delete?: boolean;
}

export const emptyHoliday: HolidayRequest = {
  name: "",
  creation: "",
  status: "",
  employee_name: "",
  from_date: "",
  to_date: "",
  leave_type: "",
  total_leave_days: 0,
  can_delete: false,
};

export interface Holiday {
  holidayBeginValue: Date;
  holidayEndValue: Date;
  holidayOptionsValue: string;
  holidayReason: string;
  holidayOptions: string;
  dataIsReady: boolean;
  holidaysAreVisible: boolean;
}

export interface AbsenceEvent {
  employee: string;
  date: string;
  isOffsite?: boolean;
  day_off?: boolean;
}

export interface EmployeeAbsenceEvent {
  name: string;
  dates: EmployeeDateAndLeaveType[];
  day_off?: boolean;
  full_name: string;
}

export interface EmployeeDateAndLeaveType {
  date: number;
  isOffsite?: boolean;
  day_off?: boolean;
}

export interface WorkplaceBooking {
  date: Date;
  workplace: string;
  absence_reason?: string;
  desk?: string;
  transportation_profile?: string;
  parking_lot_desired: boolean;
  has_parking_lot: boolean;
  parking_lot_available: boolean;
  blocked_desks: number;
  free_desks: number;
  others_at_office: OtherEmployeeAtOffice[];
  others_at_homeoffice: string[];
  others_absent: string[];
  others_offsite: string[];
  notes: Note[];
}

export interface Note {
  note: string;
  employee: string;
}

export interface workplace {
  name: string;
  code: string;
}

export const workplaces: workplace[] = [
  { name: "Büro", code: "office" },
  { name: "Zuhause", code: "homeoffice" },
  { name: "Außerhalb", code: "offsite" },
  { name: "Abwesend", code: "absent" },
];

export interface OtherEmployeeAtOffice {
  employee: string;
  desk: string;
}

export interface Employee {
  name: string;
  first_name: string;
  last_name: string;
  is_external: boolean;
  full_name: string;
  abbreviation: string;
  user_id?: string;
  self?: boolean;
}

export interface EmployeeState {
  name: string;
  fullName: string;
  state: string;
  desk: string;
}

// A stateless description of a desk in the office, this information is read only for the frontend
export interface Desk {
  name: string;
  title: string;
  x: number;
  y: number;
  orientation: string;
  notes: string | null;
}

// A stateful description of a desk with the employee id which is using it
// The state can be set by frontend and is stored through backend
export interface DeskState {
  name: string;
  available: boolean;
  blocked_by: string;
  favorite: boolean;
  block_type: string | null;
  reason: string | null;
}

export interface fileUploadResponse {
  name: string;
  owner: string;
  creation: string;
  modified: string;
  modified_by: string;
  idx: number;
  docstatus: number;
  file_name: string;
  is_private: number;
  is_home_folder: number;
  is_attachments_folder: number;
  file_size: number;
  file_url: string;
  folder: string;
  is_folder: number;
  content_hash: string;
  uploaded_to_dropbox: number;
  uploaded_to_google_drive: number;
  doctype: string;
}

export interface calendarFilter {
  showMyself: boolean;
  searchTerm: string;
  favorite: string;
}

export interface erpNextUserProfile {
  show_birthdays: 0 | 1;
  show_absences: 0 | 1;
  show_presences: 0 | 1;
  calculate_end_of_working_day: 0 | 1;
  default_transportation: string;
  parking_reservation_to_personal_mail: 0 | 1;
}

export interface erpNextBaseProfile {
  date_of_birth: string | null;
  date_of_birth_date: Date | null;
  emergency_phone_number: string | null;
  person_to_be_contacted: string | null;
  person_to_be_contacted_relation: string | null;
  cell_number: string | null;
  personal_email: string | null;
  current_address: string | null;
  nationality: string | null;
}

export interface erpNextBankProfile {
  bank_name: string | null;
  bank_ac_no: string | null;
}

export interface erpNextHealthProfile {
  health_insurance_provider: string | null;
  health_insurance_no: string | null;
}

export interface erpNextWorkHistory {
  bio: string | null;
  experience: string | null;
  experience_date: Date | null;
}

export interface erpWorkHistoryProject {
  name: string;
  project_name: string;
  customer_name: string;
  project_content: string;
  team_size: string;
  customer_category: string;
  editable: boolean;
}

export interface erpNextCompanyMood {
  mood_seven: number;
  mood_twenty_eight: number;
}

export interface searchLink {
  value: string;
  description: string;
}

export interface erpNextBirthdays {
  name: string;
  date_of_birth: string;
}

export interface erpNextProfileEducation {
  name: string;
  school_univ: string;
  qualification: string;
  level: string;
  year_of_passing: string;
  class_per: string;
  maj_opt_subj?: string;
}

export interface erpNextProfileExternalWorkHistory {
  name: string;
  designation: string;
  project_content: string;
  tasks: string;
  keywords: string;
  total_experience: string;
  end_date: string;
  team_size: string;
  customer_category: string;
  company_name: string;
}

export interface erpNextDaysInOfficeStats {
  avg_office_days: number;
  weeks: number;
}

export interface erpNextProfileInternalWorkHistory {
  name: string;
  designation_desc: string;
  project: string;
  project_content: string;
  tasks: string;
  keywords: string;
  from_date: Date;
  to_date: Date;
  team_size: string;
  customer_category: string;
}

export interface erpNextProfileSkill {
  name: string;
  skill: string;
  proficiency: number;
  evaluation_date: string;
}

export interface erpNextAvailableSkills {
  name: string;
  skill_name: string;
  description: string | null;
}

export interface erpNextProfileTraining {
  name: string;
  designation: string;
  training_date: string;
  training_type: string;
  duration: string;
}

export interface erpNextInventory {
  name: string;
  issue_name: string;
  issue_transaction_date: Date;
  issue_confirmed_by: string;
  issue_confirmed_at: Date;
  return_name: string;
  return_transaction_date: Date;
  return_confirmed_by: string;
  return_confirmed_at: Date;
  item_name: string;
  item_description: string;
  item_serial: string;
}

export interface ErpNextVisitorLog {
  name: string;
  date: Date | string;
  visitor_name: string;
  company_name: string | undefined | null;
  host: string;
  email: string;
  phone: string | undefined | null;
  reason: string | undefined | null;
  left?: Date | null;
  registration_code: string | undefined | null;
  email_send: boolean;
}

export interface ErpNextTodayVisitorLog {
  company_name: string | undefined | null;
  date: Date | string;
  host: string;
  left?: Date | null;
  name: string;
  reason: string | undefined | null;
  registration_code?: string | null;
  visitor_name: string;
}

export interface ErpNextVisitorRegistration {
  date: Date | string;
  visitor_name: string;
  email: string;
  company_name?: string | null;
  phone?: string | null;
  reason?: string | null;
}

export interface erpNextTransportationProfile {
  name: string;
  icon: string;
  title: string;
  primary_vehicle: string;
  co2_consumption: number;
  distance: number;
  parking_lot_desired: boolean;
}

export interface erpNextOrganisationChart {
  projects: Project[];
  staff_units: Person[];
}

export interface Project {
  name: string;
  project_name: string;
  members: Person[];
}

export interface Person {
  role: string;
  employee: string;
  substitution: boolean;
}

export interface ChartNode {
  id: number;
  parentId: number | null;
  positionName: string;
  name: string;
  height: number;
}

export interface DesksBlockedByUser {
  name: string;
  date: Date | string;
  desk: string;
  reason: string;
}

export interface DeskBlocking {
  date: string;
  desk: string;
  reason: string;
}

export interface DeskToRender {
  desk: Desk;
  selected: boolean;
  blocked: boolean;
}

export enum VoucherType {
  BYOD = "Byod",
  GUEST = "Guest",
}

export interface WifiVoucherCreationDto {
  voucher_type: VoucherType;
  reason: string;
}
