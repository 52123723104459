import { Desk, erpNextTransportationProfile } from "@/data-types";
import erpnextApi from "@/rest/ErpnextApi.ts";
import { Module } from "vuex";
import { RootState } from "@/store";

export interface WorkplaceStoreState {
  wasDeskWidgetClicked: boolean;
  desks: Map<string, Desk>;
  transportationProfiles: erpNextTransportationProfile[];
}

const workplaceStore: Module<WorkplaceStoreState, RootState> = {
  state: () => ({
    desks: new Map<string, Desk>(),
    transportationProfiles: [],
    wasDeskWidgetClicked: false,
  }),
  getters: {
    getDesks(state: WorkplaceStoreState) {
      return state.desks;
    },
    getTransportationProfiles(state: WorkplaceStoreState) {
      return state.transportationProfiles;
    },
    getDeskWidgetClickedInfo(state: WorkplaceStoreState) {
      return state.wasDeskWidgetClicked;
    },
  },
  mutations: {
    setDesks(state: WorkplaceStoreState, desks: Desk[]) {
      for (const desk of desks) state.desks.set(desk.name, desk);
    },
    setTransportationProfiles(state: WorkplaceStoreState, profiles: erpNextTransportationProfile[]) {
      state.transportationProfiles.length = 0;
      state.transportationProfiles.push(...profiles);
    },
    setDeskWidgetClickedInfo(state: WorkplaceStoreState, wasDeskWidgetClicked: boolean) {
      state.wasDeskWidgetClicked = wasDeskWidgetClicked;
    },
  },
  actions: {
    async fetchTransportationProfiles(context: any) {
      if (context.state.transportationProfiles.length === 0) {
        context.commit("setTransportationProfiles", await erpnextApi.getTransportationProfiles());
      }
    },
    async reloadTransportationProfiles(context: any) {
      context.commit("setTransportationProfiles", await erpnextApi.getTransportationProfiles());
    },
    async fetchDesks(context: any) {
      if (context.state.desks.size === 0) {
        context.commit("setDesks", await erpnextApi.getDesks());
      }
    },
    setDeskWidgetClickedInfo(wasDeskWidgetClicked: any) {
      wasDeskWidgetClicked.value = wasDeskWidgetClicked;
    },
  },
};

export default workplaceStore;
