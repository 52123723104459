<template>
  <Avatar class="employee-avatar" :size="avatarSize" :style="{ background, color }" :label="employee.abbreviation" />
</template>

<script setup lang="ts">
import Avatar from "primevue/avatar";
import { Employee } from "@/data-types";
import { foregroundForBackground } from "@/utils/UiUtils.ts";
import { colorFromString } from "@/utils/ColorFromString";

interface Props {
  employee: Employee;
  avatarSize?: "normal" | "large" | "xlarge";
}

const props = withDefaults(defineProps<Props>(), {
  avatarSize: "normal",
});

const employeeName = props.employee.first_name + props.employee.name + props.employee.last_name;
const background = colorFromString(employeeName);
const color = foregroundForBackground(background);
</script>

<style scoped></style>
