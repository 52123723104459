<template>
  <Toast :breakpoints="{ '920px': { width: '100%', right: '0', left: '0' } }" position="bottom-right" group="br">
    <template #message="slotProps">
      <div class="flex p-ai-center" style="width: 100%; height: 100%">
        {{ slotProps.message.detail }}
      </div>
    </template>
  </Toast>
  <the-navigation />
  <router-view />
</template>

<script setup lang="ts">
import TheNavigation from "@/ui/TheNavigation.vue";
import Toast from "primevue/toast";
</script>

<style>
body {
  padding: 0;
  margin: 4px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: var(--black);
}
</style>
