<template>
  <base-widget v-if="isInOfficeToday">
    <ProgressSpinner v-if="!loadedContent" class="p-d-flex p-jc-center" />
    <div v-if="loadedContent" class="officeWidget">
      <div class="heading">
        {{ message }}
      </div>
      <div class="desk" @click="handleLinkClick">
        {{ deskName }}
      </div>
    </div>
    <event-widget v-if="disableEventWidgetUntilLoadingFinished" />
  </base-widget>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import BaseWidget from "@/ui/BaseWidget.vue";
import { useStore } from "vuex";
import EventWidget from "./EventWidget.vue";
import erpnextApi from "@/rest/ErpnextApi.ts";
import { useRouter } from "vue-router";
import ProgressSpinner from "primevue/progressspinner";
import { calculateWorkplaceRouterPath } from "@/utils/WorkplacePathCalculator.ts";

const store = useStore();
const router = useRouter();
const deskName = ref();
const date = new Date();
const result = ref();
const listOfDesks = ref();
const message = ref("");
const loadedContent = ref(false);
const isInOfficeToday = ref(true);
const disableEventWidgetUntilLoadingFinished = ref(false);

const handleLinkClick = () => {
  store.commit("setDeskWidgetClickedInfo", true);
  router.push(calculateWorkplaceRouterPath());
};

onMounted(async () => {
  try {
    await Promise.all([
      erpnextApi.getWorkplaceBookings(date, 1, true).then((x) => (result.value = x)),
      erpnextApi.getDesks().then((x) => (listOfDesks.value = x)),
    ]);
    const deskNameList = listOfDesks.value.find((desk: { name: string }) => desk.name === result.value[0].desk);
    if (deskNameList) {
      message.value = "Dein Tisch im Büro ist heute";
      deskName.value = deskNameList.title;
    } else {
      isInOfficeToday.value = false;
    }
    loadedContent.value = true;
    disableEventWidgetUntilLoadingFinished.value = true;
  } catch (error) {
    console.error(error);
  }
});
</script>

<style scoped>
.officeWidget {
  margin: 1rem;
  padding: 0.5rem;
  background-color: var(--greystep);
  border-radius: 5px;
}

.heading {
  font-size: 24px;
  border-bottom: 1px solid var(--white);
}

.desk {
  padding-top: 1rem;
  font-size: 23px;
  font-weight: bold;
  color: var(--primary);
}

@media (max-width: 700px) {
  .heading {
    font-size: 20px;
  }

  .desk {
    font-size: 20px;
  }
}
</style>
