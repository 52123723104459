<template>
  <Tag v-if="publicHoliday" class="color">
    <div style="padding: 0.1rem" class="p-button-sm">
      {{ publicHoliday }}
    </div>
  </Tag>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Tag from "primevue/tag";

export default defineComponent({
  name: "WorkplacePublicHoliday",
  components: { Tag },
  props: {
    publicHoliday: { required: true, type: String },
  },
});
</script>

<style scoped>
.color {
  background-color: var(--pink);
  color: var(--white);
}
</style>
