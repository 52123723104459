import { BookingDay, BookingPositionDescription } from "@/data-types.ts";

export function evaluateBookingPositionsForCurrentWeek(
  bookingPositionsDescriptionsByName: Map<string, BookingPositionDescription>,
  startOfWeek: BookingDay,
  endOfWeek: BookingDay,
): BookingPositionDescription[] {
  const availableBookingPositionsForCurrentWeek: BookingPositionDescription[] = [];
  bookingPositionsDescriptionsByName.forEach((desc: BookingPositionDescription) => {
    const isPositionInDay = startOfWeek.positionHours.some((pos) => pos.name === desc.name);
    if (isPositionInDay || desc.closed) return;
    if (!desc.isUserAllowedToBook) return;

    const changeableTill = desc.changeable_till ? new Date(desc.changeable_till) : null;
    const changeableFrom = desc.changeable_from ? new Date(desc.changeable_from) : null;
    const firstDayDate = startOfWeek.date;
    const lastDayDate = endOfWeek.date;

    if ((changeableTill && firstDayDate && changeableTill < firstDayDate) || (changeableFrom && lastDayDate && changeableFrom > lastDayDate)) return;
    availableBookingPositionsForCurrentWeek.push(desc);
  });
  return availableBookingPositionsForCurrentWeek;
}
