function calculateHash(str: string): number {
  // This is the fnv1a algorithmus to calculate a hash
  let hash = 2166136261;
  for (let i = 0; i < str.length; i++) {
    hash ^= str.charCodeAt(i);
    hash += (hash << 1) + (hash << 4) + (hash << 7) + (hash << 8) + (hash << 24);
  }
  return hash >>> 0; // Ensure it's an unsigned 32-bit integer
}

function hashToRGB(hash: number): string {
  const r = (hash & 0xff0000) >> 16; // Extract bits 16-23
  const g = (hash & 0x00ff00) >> 8; // Extract bits 8-15
  const b = hash & 0x0000ff; // Extract bits 0-7

  return `#${r.toString(16).padStart(2, "0")}${g.toString(16).padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;
}

export function colorFromString(str: string): string {
  let hash = calculateHash(str);
  for (let i = 0; i < 17; i++) {
    hash = calculateHash(hash.toString());
  }
  const color = hashToRGB(hash);
  return color;
}
