<template>
  <Dialog v-model:visible="displayDialog" header="Ausbildung" :dismissable-mask="true" :modal="true" :style="{ width: isMobile ? '100vw' : '60vw' }">
    <div>
      <div class="dialogContainer">
        <div class="card flex justify-content-center educationInput">
          <span>Hochschule / Universität</span>
          <InputText
            v-model="v$.school_univ.$model"
            :style="{ width: isMobile ? '80vw' : '20vw' }"
            type="text"
            :class="{ 'p-invalid': v$.school_univ.$invalid && submitted }"
          />
          <small v-if="v$.school_univ.$invalid && submitted" class="p-error">
            {{ v$.school_univ.required.$message }}
          </small>
        </div>
        <div class="card flex justify-content-center educationInput">
          <span>Abschluss</span>
          <InputText
            v-model="v$.qualification.$model"
            :style="{ width: isMobile ? '80vw' : '29vw' }"
            type="text"
            :class="{ 'p-invalid': v$.qualification.$invalid && submitted }"
          />
          <small v-if="v$.qualification.$invalid && submitted" class="p-error">
            {{ v$.qualification.required.$message }}
          </small>
        </div>
        <div class="card flex justify-content-center educationInput">
          <span>Level</span>
          <!--Validation dafür funktioniert noch nicht-->
          <Dropdown
            v-model="v$.level.$model"
            :options="levels"
            placeholder="Level auswählen"
            option-label="displayName"
            class="w-full md:w-14rem"
            :class="{ 'p-invalid': v$.level.$invalid && submitted }"
          />
          <small v-if="v$.level.$invalid && submitted" class="p-error">
            {{ v$.level.required.$message }}
          </small>
        </div>
      </div>
      <div class="dialogContainer">
        <div class="card flex justify-content-center educationInput">
          <span>Abschlussjahr</span>
          <InputText v-model="v$.year_of_passing.$model" type="number" :class="{ 'p-invalid': v$.year_of_passing.$invalid && submitted }" />
          <small v-if="v$.year_of_passing.$invalid && submitted" class="p-error">
            {{ v$.year_of_passing.required.$message }}
          </small>
        </div>
        <div class="card flex justify-content-center educationInput">
          <span>Note (optional)</span>
          <InputText v-model="educationDataObj.class_per" type="text" />
        </div>
        <div class="card flex justify-content-center educationInput">
          <span>Vertiefungsrichtung (optional)</span>
          <InputText v-model="educationDataObj.maj_opt_subj" type="text" />
        </div>
      </div>
      <div class="editButton">
        <ProgressSpinner v-if="isLoading" class="p-d-flex p-jc-center" />
        <Button v-if="educationDataObj.name && !isLoading" @click="updateProfileEducation(!v$.$invalid)"> Aktualisieren </Button>
        <Button v-if="!educationDataObj.name && !isLoading" @click="addProfileEducation(!v$.$invalid)"> Hinzufügen </Button>
      </div>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import { inject, onMounted, Ref, ref, watch } from "vue";
import erpnextApi from "@/rest/ErpnextApi.ts";
import Dialog from "primevue/dialog";
import { useToast } from "primevue/usetoast";
import { dialog } from "@/keys";
import { helpers, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Dropdown from "primevue/dropdown";
import ProgressSpinner from "primevue/progressspinner";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import { showErrorToast, showSuccessToast } from "@/utils/Toast";

interface Props {
  educationData: any;
}

const props = defineProps<Props>();

const emit = defineEmits(["DataChanged"]);

interface Level {
  name: string;
  displayName: string;
}

const levels: Ref<Level[]> = ref([
  { name: "Graduate", displayName: "Master" },
  { name: "Post Graduate", displayName: "Promotion" },
  { name: "Under Graduate", displayName: "Bachelor" },
]);

const educationDataObj = ref(props.educationData);
const toast = useToast();
const isLoading = ref(false);
const displayDialog = inject(dialog, ref(false));
const width = window.innerWidth;
const isMobile = ref(false);
const selectedLevel = levels.value.find((level) => level.name === educationDataObj.value.level);
if (selectedLevel) {
  educationDataObj.value.level = selectedLevel;
}

const rules = {
  name: {},
  school_univ: { required: helpers.withMessage("Bitte Uni angeben", required) },
  qualification: { required: helpers.withMessage("Bitte Abschluss angeben", required) },
  level: { required: helpers.withMessage("Bitte Level angeben", required) },
  year_of_passing: { required: helpers.withMessage("Bitte Abschlussjahr angeben", required) },
  class_per: {},
  maj_opt_subj: {},
};

const v$ = useVuelidate(rules, educationDataObj);
const submitted = ref(false);

watch(
  () => props.educationData[0],
  (newVal) => {
    educationDataObj.value = newVal;
  },
);

onMounted(() => {
  if (width < 1400) {
    isMobile.value = true;
  }
});

async function updateProfileEducation(isFormValid: boolean) {
  submitted.value = true;
  if (!isFormValid) {
    showErrorToast(toast, "Bitte die Fehler in den Eingabefeldern beheben");
    return;
  }
  isLoading.value = true;
  await erpnextApi
    .updateProfileEducation(
      educationDataObj.value.name,
      educationDataObj.value.school_univ,
      educationDataObj.value.qualification,
      educationDataObj.value.level.name,
      educationDataObj.value.year_of_passing,
      educationDataObj.value?.class_per,
      educationDataObj.value?.maj_opt_subj,
    )
    .then(() => {
      showSuccessToast(toast, "Ausbildung erfolgreich aktualisiert");
      emit("DataChanged");
      displayDialog.value = false;
    })
    .catch((err: Error) => showErrorToast(toast, err.message))
    .finally(() => (isLoading.value = false));
}

async function addProfileEducation(isFormValid: boolean) {
  submitted.value = true;
  if (!isFormValid) {
    showErrorToast(toast, "Bitte die Fehler in den Eingabefeldern beheben");
    return;
  }
  isLoading.value = true;
  await erpnextApi
    .addProfileEducation(
      educationDataObj.value.school_univ,
      educationDataObj.value.qualification,
      educationDataObj.value.level.name,
      educationDataObj.value.year_of_passing,
      educationDataObj.value?.class_per,
      educationDataObj.value?.maj_opt_subj,
    )
    .then(() => {
      showSuccessToast(toast, "Ausbildung erfolgreich hinzugefügt");
      emit("DataChanged");
      displayDialog.value = false;
      submitted.value = false;
    })
    .catch((err: Error) => showErrorToast(toast, err.message))
    .finally(() => (isLoading.value = false));
}
</script>

<style scoped>
.dialogContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
  padding: 10px;
  border: 1px solid var(--greystep);
  border-radius: 5px;
  background-color: var(--greystep);
  flex-wrap: wrap;
}

.educationInput {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

span {
  margin-bottom: 5px;
  color: var(--white);
}

.editButton {
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
  margin-top: 1rem;
}

@media (max-width: 700px) {
  .dialogContainer {
    margin: 0;
    padding: 0;
  }

  .educationInput {
    margin: 0.5rem;
  }
}
</style>
