<template>
  <div class="headerBox noBorder dayColumn">
    Gesamt <br />
    (BP)
  </div>

  <div v-for="index in 3" :key="index" class="box" />

  <!-- total work time -->
  <div class="box">
    <div class="p-text-center">
      {{ totalWorkTimeString }}
    </div>
  </div>

  <template v-if="isProjectShowing">
    <div v-for="(item, index) in positionHours" :key="index">
      <div class="box projectBox">
        {{ item.hours }}
      </div>
    </div>
  </template>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from "vue";
import { BookingDay, BookingDayPosition } from "@/data-types";
import { buildTimeString } from "@/utils/Helper";
import clonedeep from "lodash.clonedeep";

interface Props {
  days: BookingDay[] | undefined;
  isProjectShowing?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isProjectShowing: false,
  days: () => [] as BookingDay[],
});
const positionHours = reactive<BookingDayPosition[]>([]);
const totalWorkTime = reactive([0, 0]);
const totalWorkTimeString = ref<string>();

onMounted(() => {
  if (props.days.length > 0) {
    positionHours.splice(0, positionHours.length, ...clonedeep(props.days[0].positionHours));
    positionHours.forEach((positionHour) => (positionHour.hours = "00:00"));
  }

  //adding all bp-hours
  props.days.forEach((day) => {
    if (day.state == "Present") {
      day.positionHours.forEach((positionHour: BookingDayPosition, index) => {
        if (positionHours[index]) {
          const totalHoursSplitted = positionHours[index].hours.split(":").map((s) => parseInt(s));
          const positionHoursSplitted = positionHour.hours.split(":").map((s) => parseInt(s));

          totalHoursSplitted[0] += positionHoursSplitted[0];
          totalHoursSplitted[1] += positionHoursSplitted[1];
          positionHours[index].hours = buildTimeString(totalHoursSplitted[0], totalHoursSplitted[1]);
        }
      });
    }
  });

  //check if minutes over 60 to add an extra hour
  positionHours.forEach((positionHour: BookingDayPosition) => {
    const positionsHoursSplitted = positionHour.hours.split(":").map((s) => parseInt(s));

    const validTotalTime = checkMinuteOverflow(positionsHoursSplitted[0], positionsHoursSplitted[1]);

    totalWorkTime[0] += validTotalTime[0];
    totalWorkTime[1] += validTotalTime[1];

    positionHour.hours = buildTimeString(validTotalTime[0], validTotalTime[1]);
  });

  totalWorkTime.splice(0, totalWorkTime.length, ...checkMinuteOverflow(totalWorkTime[0], totalWorkTime[1]));

  totalWorkTimeString.value = buildTimeString(totalWorkTime[0], totalWorkTime[1]);
});

function checkMinuteOverflow(hours: number, minutes: number) {
  if (minutes == 60) {
    hours += 1;
    minutes = 0;
  } else if (minutes > 60) {
    const extraHours = Math.trunc(minutes / 60);
    hours += extraHours;
    minutes = minutes - extraHours * 60;
  }

  return [hours, minutes];
}
</script>

<style scoped>
.box {
  border-left: 0.1rem solid var(--tableBackground);
}
</style>
