<template>
  <div :id="getWorkdayForDate(booking.date)" style="font-size: 1.5rem; font-weight: bold" class="p-my-2 p-mr-3">
    {{ getWorkdayForDate(booking.date) }} {{ formatDateWithoutYear(booking.date) }}
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { formatDateWithoutYear, getWorkdayForDate } from "@/utils/Helper";
import { WorkplaceBooking } from "@/data-types";

export default defineComponent({
  name: "WorkplaceDate",
  props: {
    booking: { required: true, type: Object as PropType<WorkplaceBooking> },
  },
  setup: () => {
    return { formatDateWithoutYear, getWorkdayForDate };
  },
});
</script>

<style scoped></style>
