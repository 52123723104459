import { CalendarPublicHoliday, weekend } from "@/data-types";

export function getHolidayName(day: number, fun: typeof isPublicHoliday | typeof isWeekend, publicHolidays: CalendarPublicHoliday[]): string {
  let holidayName = "";
  for (let i = 0; i < publicHolidays.length; i++) {
    if (fun(publicHolidays[i], day)) {
      holidayName = publicHolidays[i].name;
      break;
    }
  }
  return holidayName;
}

export function isWeekend(holiday: CalendarPublicHoliday, day: number): boolean {
  return holiday.day === day && weekend.indexOf(holiday.name) >= 0;
}

export function isPublicHoliday(holiday: CalendarPublicHoliday, day: number): boolean {
  return holiday.day === day && weekend.indexOf(holiday.name) < 0;
}
