<template>
  <div class="p-d-flex p-flex-column p-flex-md-row p-jc-center">
    <div class="p-d-flex p-ai-center p-jc-center">
      <h2>{{ title }}</h2>
    </div>
    <div class="p-pl-0 p-pl-md-5 p-d-flex p-ai-center p-jc-center">
      <Button icon="pi pi-angle-double-left" class="primaryButton" style="width: 4rem" :disabled="disablePreviousWeek" @click="onChangeWeekBackward" />
      <Calendar
        id="calendarDate"
        v-model="currentDate"
        :readonly="!calendarExists"
        class="p-mx-3 myCalendar"
        :date-format="dateDisplay"
        :manual-input="false"
        show-week
        :show-icon="calendarExists"
        @date-select="onDateSelected"
      />
      <Button icon="pi pi-angle-double-right" class="primaryButton" style="width: 4rem" :disabled="disableNextWeek" @click="onChangeWeekForward" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import Button from "primevue/button";
import Calendar from "primevue/calendar";

interface Props {
  disablePreviousWeek?: boolean;
  disableNextWeek?: boolean;
  title?: string;
  year?: number;
  week?: number;
  isSwipeDeactivated?: boolean;
  isForwardSwipeDeactivated?: boolean;
  calendarExists?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  disablePreviousWeek: true,
  disableNextWeek: true,
  title: "",
  year: 0,
  week: 0,
  isSwipeDeactivated: false,
  isForwardSwipeDeactivated: false,
  calendarExists: true,
});

const emit = defineEmits<{
  changeWeekBackward: [];
  changeWeekForward: [];
  changeWeek: [value: Date | undefined];
}>();
const currentDate = ref<Date>();
const dateDisplay = computed(() => props.year + "': Woche '" + props.week);

watch(props, () => {
  currentDate.value = new Date(props.year, 0, 2 + (props.week - 1) * 7);
});

let touchstartX = 0;
let touchendX = 0;
let touchstartY = 0;
let touchendY = 0;

function checkDirection() {
  const swipeDistance = 200;
  if (
    touchstartX - touchendX > swipeDistance &&
    touchstartY - touchendY < swipeDistance &&
    touchstartY - touchendY > -swipeDistance &&
    !props.isSwipeDeactivated &&
    !props.isForwardSwipeDeactivated
  )
    emit("changeWeekForward");
  if (
    touchendX - touchstartX > swipeDistance &&
    touchstartY - touchendY < swipeDistance &&
    touchstartY - touchendY > -swipeDistance &&
    !props.isSwipeDeactivated
  )
    emit("changeWeekBackward");
}

document.addEventListener("touchstart", (e) => {
  touchstartX = e.changedTouches[0].screenX;
  touchstartY = e.changedTouches[0].screenY;
});
document.addEventListener("touchend", (e) => {
  touchendX = e.changedTouches[0].screenX;
  touchendY = e.changedTouches[0].screenY;
  checkDirection();
});

function onChangeWeekBackward() {
  emit("changeWeekBackward");
}

function onChangeWeekForward() {
  emit("changeWeekForward");
}

function onDateSelected() {
  emit("changeWeek", currentDate.value);
}
</script>

<style scoped>
.date {
  min-width: 115px;
}
</style>
<style>
.myCalendar input {
  border: none;
  box-shadow: none;
  text-align: center;
  cursor: default;
}

.myCalendar input.p-inputtext.p-component:focus {
  border: none;
  box-shadow: none;
}
</style>
