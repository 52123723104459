<template>
  <Dialog v-model:visible="showDialog" :modal="true" :dismissable-mask="true">
    <template #header>
      <WorkplaceDate :booking="booking" />
    </template>

    <DataTable :value="employeeStates" responsive-layout="scroll" class="p-datatable-sm">
      <Column field="fullName" header="Name" />
      <Column field="state" header="Status">
        <template #body="slotProps">
          <span :class="slotProps.data.state === 'Büro' ? 'state' : 'white'">
            {{ slotProps.data.state }}
          </span>
        </template>
      </Column>
      <Column field="desk" header="Platz">
        <template #body="slotProps">
          <span class="state">
            {{ slotProps.data.desk }}
          </span>
        </template>
      </Column>
    </DataTable>
  </Dialog>
  <div v-if="userProfile.show_presences" class="p-d-flex p-ai-center p-flex-wrap">
    <EmployeeIcons :class="{ 'p-disabled': disabled }" :employees="allFavoritesEmployees" />
    <div :class="{ 'text-disable': disabled }" class="p-link text-primary" @click="onShowAll">Alle anzeigen</div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import store from "@/store";
import { Desk, Employee, EmployeeState, WorkplaceBooking } from "@/data-types";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import WorkplaceDate from "@/features/workspace/components/WorkplaceDate.vue";
import EmployeeIcons from "@/features/workspace/components/EmployeeIcons.vue";

interface Props {
  filter: string[];
  disabled: boolean;
  booking: WorkplaceBooking;
  employees: Map<string, Employee>;
  desks: Map<string, Desk>;
}

const props = defineProps<Props>();

const employeeList = ref();
const showDialog = ref(false);
const employeeStates = ref<EmployeeState[]>([]);
const isAtOffice = ref(false);

const favorites = computed(() => props.filter.map((item) => store.getters.getFavoriteEmployees.get(item)));
const noDuplicateFavorites = computed(() => [...new Set(favorites.value)].join(",").split(","));

const allFavoritesEmployees = computed(() => {
  return props.booking.others_at_office
    .filter((otherEmployee) => noDuplicateFavorites.value.find((employeeName: string) => employeeName === otherEmployee.employee) != null)
    .map((otherEmployee) => props.employees.get(otherEmployee.employee)!);
});

const userProfile = computed(() => store.getters.getUserProfile);

function onShowAll() {
  employeeStates.value.length = 0;
  for (const employee of props.employees.values()) {
    if (employee.self == true || employee.is_external) continue;
    let state = "-";
    isAtOffice.value = false;
    let desk = null;
    if (props.booking.others_at_homeoffice.find((x) => x === employee.name)) {
      state = "Zuhause";
    } else if (props.booking.others_absent.find((x) => x === employee.name)) {
      state = "Abwesend";
    } else if (props.booking.others_offsite.find((x) => x === employee.name)) {
      state = "Außerhalb";
    } else {
      const at_office = props.booking.others_at_office.find((x) => x.employee === employee.name);
      if (at_office) {
        state = "Büro";
        isAtOffice.value = true;
        desk = props.desks.get(at_office.desk)?.title;
      }
    }
    employeeStates.value.push({
      name: employee.name,
      fullName: employee.full_name,
      state: state,
      desk: desk ?? "",
    });
    employeeStates.value.sort((a, b) => {
      const fa = a.fullName.toLowerCase(),
        fb = b.fullName.toLowerCase();
      if (fa < fb) return -1;
      if (fa > fb) return 1;
      return 0;
    });
  }

  employeeList.value = props.employees;
  showDialog.value = true;
}
</script>

<style scoped>
.text-primary {
  color: var(--primary);
}

.state {
  color: var(--primary);
}

.text-disable {
  opacity: 0.7;
}
</style>
