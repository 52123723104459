<template>
  <Dialog v-model:visible="displayDialog" header="Zeit auf andere Buchungsposition buchen" :dismissable-mask="true" :modal="true">
    <div>Name: {{ activeBookingPositionFullName }}</div>
    <div>Stunden: {{ bookingDayPosition.hours }}</div>
    <div>Kommentar: {{ bookingDayPosition.comment }}</div>
    <br />
    <div>Tauschen mit:</div>
    <br />
    <div />
    <div class="card flex justify-content-center educationInput">
      <span>Buchungsposition</span>
      <!--Validation dafür funktioniert noch nicht-->
      <Dropdown
        v-model="selectedBookingPosition"
        :options="BookingPositionOptions"
        placeholder="Buchungsposition auswählen"
        option-label="full_name"
        class="w-full md:w-14rem"
      />
    </div>
    <Button @click="getBookingPositionForChange"> Speichern</Button>
  </Dialog>
</template>

<script setup lang="ts">
import { inject, onMounted, provide, ref } from "vue";
import { dialog } from "@/keys";
import Dialog from "primevue/dialog";
import { BookingDayPosition, BookingPositionDescription } from "@/data-types.ts";

interface Props {
  bookingDayPosition: BookingDayPosition;
  bookingPositionDescriptionByName: Map<string, BookingPositionDescription>;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "changeHoursOnBp", data: any): void;
}>();

const bookingDayPosition = ref(props.bookingDayPosition);
const bookingPositionDescriptionByName = ref(props.bookingPositionDescriptionByName);
const BookingPositionOptions = ref();
const selectedBookingPosition = ref();
const activeBookingPositionFullName = ref();
const displayDialog = inject(dialog, ref(false));
const isOpen = ref(false);
provide(dialog, isOpen);

onMounted(iterateMap);

function getBookingPositionForChange() {
  emit("changeHoursOnBp", { 0: selectedBookingPosition.value, 1: bookingDayPosition.value.name });
}

function iterateMap() {
  const newArray = [];
  for (const entry of bookingPositionDescriptionByName.value.values()) {
    if (bookingDayPosition.value.name != entry.name) {
      newArray.push({ full_name: entry.full_name });
      BookingPositionOptions.value = newArray;
    }
    if (bookingDayPosition.value.name === entry.name) {
      activeBookingPositionFullName.value = entry.full_name;
    }
  }
  return newArray;
}
</script>

<style scoped>
.educationContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--greystep);
  background-color: var(--greystep);
  border-radius: 5px;
  margin-top: 0.5rem;
}

.text {
  margin-bottom: 10px;
  font-size: 18px;
  color: var(--white);
  border-bottom: 1px solid var(--grey);
}

.editButton {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.buttons {
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
}

.info {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--grey);
}

.educationBox {
  width: 100%;
  margin-top: 1rem;
  padding: 0.5rem;
  border: 1px solid #969696;
  border-radius: 3px;
  margin-left: 3px;
}

.educationHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.caption {
  font-size: 18px;
}
</style>
