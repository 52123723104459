<template>
  <BaseSideCardWeekly v-if="loadedWholeMonth">
    <div class="p-text-bold" style="color: var(--white)">Monatssumme</div>
    <div class="p-text-italic p-mb-2" style="color: var(--white)">
      {{ monthName }}
    </div>
    <overview-data-entry
      v-for="(item, index) in totalProjectWork"
      :key="index"
      :title="item.name"
      :value="item.hours"
      :is-sub-item="item.isSubitem"
      :tooltip="'Gebuchte Stunden'"
    />
    <div id="divider" class="p-mb-2 p-text-bold" />
    <overview-data-entry :title="'Gesamt'" :value="totalWorkTime" />
  </BaseSideCardWeekly>
  <BaseSideCardWeekly v-else>
    <ProgressSpinner class="p-d-flex p-jc-center" />
  </BaseSideCardWeekly>
</template>

<script lang="ts">
import Tooltip from "primevue/tooltip";
import ProgressSpinner from "primevue/progressspinner";

import { computed } from "vue";
import { buildTimeString, splitTimeString } from "@/utils/Helper";
import OverviewDataEntry from "@/ui/OverviewDataEntry.vue";
import BaseSideCardWeekly from "@/ui/BaseSideCardWeekly.vue";

export interface BPEntry {
  name: string;
  hours: number;
  minutes: number;
}

export interface PositionItem {
  isSubitem: boolean;
  name: string;
  hours: string;
}

export default {
  name: "MonthlyTotal",
  components: { OverviewDataEntry, ProgressSpinner, BaseSideCardWeekly },
  directives: { tooltip: Tooltip },
  props: {
    bookingPositions: {
      type: Map,
      required: true,
    },
    monthData: {
      type: Object,
      required: true,
    },
  },
  setup(props: any) {
    const loadedWholeMonth = computed(() => props.monthData.length > 25);
    const monthName = computed(() => props.monthData[0].date.toLocaleString("default", { month: "long" }));

    const totalWorkTime = computed(() => {
      let totalHours = 0;
      let totalMinutes = 0;

      for (const entry of props.monthData) {
        if (entry.state === "Present") {
          for (const ph of entry.positionHours) {
            const split = splitTimeString(ph.hours);
            totalHours += split.hours;
            totalMinutes += split.minutes;
          }
        }
      }
      return buildTimeString(totalHours, totalMinutes);
    });

    const totalProjectWork = computed(() => {
      const projects = new Map<string, Map<string, BPEntry>>();
      const items: PositionItem[] = [];

      for (const entry of props.monthData) {
        if (entry.state === "Present") {
          for (const ph of entry.positionHours) {
            const bookingPosition = props.bookingPositions.get(ph.name) ?? {
              project: "Loading...",
            };
            let project = projects.get(bookingPosition.project);
            if (!project) {
              project = new Map<string, BPEntry>();
              projects.set(bookingPosition.project, project);
            }

            let positionItem = project.get(ph.name);
            if (!positionItem) {
              positionItem = {
                name: bookingPosition.description,
                hours: 0,
                minutes: 0,
              };
              project.set(ph.name, positionItem);
            }

            const split = splitTimeString(ph.hours);
            positionItem.hours += split.hours;
            positionItem.minutes += split.minutes;
          }
        }
      }

      for (const [projectName, project] of projects) {
        let hours = 0,
          minutes = 0;
        for (const bp of project.values()) {
          hours += bp.hours;
          minutes += bp.minutes;
        }

        if (project.size <= 1) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          for (const bp of project.values()) {
            items.push({
              name: projectName,
              hours: buildTimeString(hours, minutes),
              isSubitem: false,
            });
          }
          continue;
        } else {
          items.push({
            name: projectName,
            hours: buildTimeString(hours, minutes),
            isSubitem: false,
          });
        }

        for (const bp of project.values()) {
          items.push({
            name: bp.name,
            hours: buildTimeString(bp.hours, bp.minutes),
            isSubitem: true,
          });
        }
      }
      return items;
    });

    return {
      totalProjectWork,
      totalWorkTime,
      monthName,
      loadedWholeMonth,
    };
  },
};
</script>

<style scoped>
#divider {
  background-color: var(--white);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  height: 0.05rem;
  border-radius: 1rem;
}
</style>
