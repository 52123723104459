<template>
  <div class="p-d-flex p-ai-center p-flex-wrap p-my-3">
    <CalendarLegendEntry name="Abwesenheit" type="absenceDates" />
    <CalendarLegendEntry name="Frei" type="dayOff" />
    <CalendarLegendEntry name="Außerhalb" type="offsiteDates" />
    <CalendarLegendEntry name="Feiertag" type="publicHoliday" />
    <CalendarLegendEntry name="Wochenende" type="weekend" />
  </div>
</template>

<script setup lang="ts">
import CalendarLegendEntry from "@/features/calendar/components/CalendarLegendEntry.vue";
</script>

<style scoped></style>
