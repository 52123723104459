<template>
  <ProfileForm :is-changed="hasChanges" @save="save(!v$.$invalid)">
    <ProfileFormHeader class="p-mt-5" label="Privater Kontakt" />
    <ProfileFormField
      label="Persönliche E-Mail"
      width="6"
      identifier="personal_email"
      :submitted="submitted"
      :is-changed="hasChanges"
      @save="save(!v$.$invalid)"
    />
    <ProfileFormField label="Telefonnummer" width="6" identifier="cell_number" :submitted="submitted" :is-changed="hasChanges" @save="save(!v$.$invalid)" />
    <ProfileFormField
      label="Notfallkontakt"
      width="6"
      identifier="person_to_be_contacted"
      :submitted="submitted"
      :is-changed="hasChanges"
      @save="save(!v$.$invalid)"
    />
    <ProfileFormField
      label="Notfallrufnummer"
      width="6"
      identifier="emergency_phone_number"
      :submitted="submitted"
      :is-changed="hasChanges"
      @save="save(!v$.$invalid)"
    />
    <ProfileFormField
      label="Beziehung zu Notfallkontakt"
      width="6"
      :is-changed="hasChanges"
      identifier="person_to_be_contacted_relation"
      :submitted="submitted"
      @save="save(!v$.$invalid)"
    />

    <ProfileFormHeader class="p-mt-5" label="Persönliches" />
    <ProfileFormField
      label="Adresse"
      type="textarea"
      width="6"
      identifier="current_address"
      :submitted="submitted"
      :is-changed="hasChanges"
      @save="save(!v$.$invalid)"
    />
    <ProfileFormField
      label="Geburtstag"
      type="calendar"
      width="6"
      identifier="date_of_birth_date"
      :submitted="submitted"
      :is-changed="hasChanges"
      @save="save(!v$.$invalid)"
    />
    <ProfileFormField
      label="Staatsangehörigkeit"
      width="6"
      identifier="nationality"
      :submitted="submitted"
      :is-changed="hasChanges"
      @save="save(!v$.$invalid)"
    />
  </ProfileForm>
</template>

<script lang="ts">
import { computed, defineComponent, inject, provide, ref } from "vue";
import ProfileForm from "@/features/profile/components/ProfileForm.vue";
import ProfileFormField from "@/features/profile/components/ProfileFormField.vue";
import ProfileFormHeader from "@/features/profile/components/ProfileFormHeader.vue";
import { email, helpers, required, requiredIf } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { loading, validate } from "@/keys";
import { validatePhoneNumber } from "@/features/profile/utils/Profile";
import { erpNextBaseProfile } from "@/data-types";
import erpnextApi from "@/rest/ErpnextApi.ts";
import { formatDateToERP } from "@/utils/Helper";
import { useToast } from "primevue/usetoast";
import clonedeep from "lodash.clonedeep";
import { showErrorToast, showSuccessToast } from "@/utils/Toast";

export default defineComponent({
  name: "ProfileUserData",
  components: { ProfileForm, ProfileFormField, ProfileFormHeader },
  setup() {
    const toast = useToast();
    const submitted = ref(false);
    const baseProfile = ref<erpNextBaseProfile>({
      date_of_birth: null,
      date_of_birth_date: null,
      emergency_phone_number: null,
      person_to_be_contacted: null,
      person_to_be_contacted_relation: null,
      cell_number: null,
      personal_email: null,
      current_address: null,
      nationality: null,
    });
    const isLoading = inject(loading, ref(true));
    const initialState = ref({});
    isLoading.value = true;
    erpnextApi
      .getProfileBaseData()
      .then((res) => {
        baseProfile.value = res;
        baseProfile.value.date_of_birth && (baseProfile.value.date_of_birth_date = new Date(baseProfile.value.date_of_birth));
        initialState.value = clonedeep(baseProfile.value);
      })
      .catch((err) => showErrorToast(toast, err))
      .finally(() => (isLoading.value = false));

    const personalEmailPresent = computed(() => (baseProfile.value.personal_email ? baseProfile.value.personal_email.length > 0 : false));
    const personalPhoneNumberPresent = computed(() => (baseProfile.value.cell_number ? baseProfile.value.cell_number.length > 0 : false));
    const emergencyPhoneNumberPresent = computed(() =>
      baseProfile.value.emergency_phone_number ? baseProfile.value.emergency_phone_number.length > 0 : false,
    );

    const rules = {
      date_of_birth: {},
      date_of_birth_date: { required: helpers.withMessage("*", required) },
      emergency_phone_number: {
        required: helpers.withMessage("*", requiredIf(emergencyPhoneNumberPresent.value)),
        validateEmergencyPhoneNumber: helpers.withMessage("Bitte eine Telefonnummer eingeben", validatePhoneNumber),
      },
      person_to_be_contacted: {},
      person_to_be_contacted_relation: {},
      cell_number: {
        required: helpers.withMessage("*", requiredIf(personalPhoneNumberPresent.value)),
        validatePersonalPhoneNumber: helpers.withMessage("Bitte eine Telefonnummer eingeben", validatePhoneNumber),
      },
      personal_email: {
        required: helpers.withMessage("*", requiredIf(personalEmailPresent.value)),
        email: helpers.withMessage("Bitte eine E-Mail eingeben", email),
      },
      current_address: { required: helpers.withMessage("*", required) },
      nationality: {},
    };
    const v$ = useVuelidate(rules, baseProfile);
    provide(validate, v$);

    const hasChanges = computed(() => {
      return JSON.stringify(baseProfile.value) !== JSON.stringify(initialState.value);
    });

    function save(isFormValid: boolean) {
      submitted.value = true;
      if (!isFormValid) return;
      isLoading.value = true;
      baseProfile.value.date_of_birth_date && (baseProfile.value.date_of_birth = formatDateToERP(baseProfile.value.date_of_birth_date));
      erpnextApi
        .updateProfileBaseData(baseProfile.value)
        .then(() => {
          showSuccessToast(toast, erpnextApi.ProfileUpdatedMessage);
          initialState.value = clonedeep(baseProfile.value);
        })
        .catch((err) => showErrorToast(toast, err))
        .finally(() => (isLoading.value = false));
    }

    return { v$, submitted, save, hasChanges };
  },
});
</script>

<style scoped></style>
