<template>
  <div v-if="employee" class="p-d-flex p-ai-center p-ml-1 p-mt-1 p-mb-4">
    <EmployeeIcon avatar-size="large" :employee="employee" />
    <div class="p-ml-3">
      <div class="title">
        {{ employee.full_name }}
      </div>
      <div>{{ employee.user_id }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Employee } from "@/data-types";
import EmployeeIcon from "@/ui/EmployeeIcon.vue";

interface Props {
  employee?: Employee | null;
}

withDefaults(defineProps<Props>(), {
  employee: null,
});
</script>

<style scoped>
.title {
  font-weight: bold;
}
</style>
