<template>
  <BaseCard>
    <div class="desk-blocking-container">
      <div class="desk-blocking-header">
        <div v-if="!isLoading" class="desk-blocking-header">
          <div v-if="computedBlockedDesks.length <= 0" id="no-blocked-desks">Bisher keine Blockierten Tische</div>
          <div v-else id="blockedDesks" class="infoHeader">Deine Blockierten Tische</div>
          <Button id="desk-block-button" @click="isDeskBlockingDialogVisible = !isDeskBlockingDialogVisible">Tisch Blockieren </Button>
        </div>
        <DialogBlockDesksInFuture
          v-model:is-dialog-visible="isDeskBlockingDialogVisible"
          :desks="desks"
          @show-desk-blocking-success="showDeskBlockingSuccess"
          @show-desk-blocking-error="showDeskBlockingError"
        ></DialogBlockDesksInFuture>
      </div>
      <ProgressSpinner v-if="isLoading" class="p-d-flex p-jc-center" style="overflow: hidden" />
      <DataTable v-else-if="computedBlockedDesks.length > 0" :value="computedBlockedDesks" class="visitor-table" scrollable>
        <Column field="date" header="Datum" sortable>
          <template #body="slotProps">
            {{ convertDateToGerDateString(convertDateStringToDate(slotProps.data.date)) }}
          </template>
        </Column>
        <Column field="reason" header="Grund" sortable />
        <Column field="title" header="Tisch" sortable />
        <Column header="Stornieren">
          <template #body="slotProps">
            <Button class="desk-cancel-button" @click="cancelDeskBlocking(slotProps.data.title, slotProps.data.desk, slotProps.data.date)">
              <span>Stornieren</span>
            </Button>
          </template>
        </Column>
      </DataTable>
    </div>
  </BaseCard>
</template>

<script lang="ts" setup>
import BaseCard from "@/ui/BaseCard.vue";
import { Desk, DesksBlockedByUser } from "@/data-types";
import { useToast } from "primevue/usetoast";
import { computed, onMounted, ref } from "vue";
import ProgressSpinner from "primevue/progressspinner";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import { showErrorToast, showSuccessToast } from "@/utils/Toast";
import { convertDateStringToDate, convertDateToGerDateString } from "@/features/visitor/utils/VisitorUtil.ts";
import erpnextApi from "@/rest/ErpnextApi.ts";
import DialogBlockDesksInFuture from "@/features/deskBlocking/components/DialogBlockDesksInFuture.vue";

const toast = useToast();
const blockedDesks = ref<DesksBlockedByUser[]>([]);
const isLoading = ref(true);
const isDeskBlockingDialogVisible = ref(false);
const desks = ref<Desk[]>([]);
const computedBlockedDesks = computed<
  (DesksBlockedByUser & {
    title: string;
  })[]
>(() =>
  blockedDesks.value.map((deskBlocking) => {
    const desk = desks.value.find((desk) => desk.name === deskBlocking.desk);
    return { ...deskBlocking, title: desk ? desk.title : "Unbekannter Tisch" };
  }),
);

onMounted(async () => {
  isLoading.value = true;
  try {
    await Promise.all([getDesks(isLoading.value), getDesksBlocked(isLoading.value)]);
  } finally {
    isLoading.value = false;
  }
});

async function getDesks(loading = false) {
  isLoading.value = true;
  try {
    desks.value = await erpnextApi.getDesks();
  } finally {
    isLoading.value = loading;
  }
}

async function cancelDeskBlocking(title: string, desk: string, date: string) {
  isLoading.value = true;
  try {
    await erpnextApi.deleteDeskBlocking(desk, date);
    showSuccessToast(toast, title + " erfolgreich storniert.");
  } catch (err: any) {
    showErrorToast(toast, err.message);
  } finally {
    await getDesksBlocked();
    isLoading.value = false;
  }
}

async function getDesksBlocked(loading = false) {
  isLoading.value = true;
  try {
    blockedDesks.value = await erpnextApi.getDeskBlockingsCreatedByUser();
  } catch (err: Error | any) {
    showErrorToast(toast, err.message);
  } finally {
    isLoading.value = loading;
  }
}

async function showDeskBlockingSuccess(toastMsg: string) {
  showSuccessToast(toast, toastMsg);
  await getDesksBlocked();
}

function showDeskBlockingError(toastMsg: string) {
  showErrorToast(toast, toastMsg);
}
</script>

<style scoped>
.desk-blocking-container {
  overflow: auto;
}

.desk-blocking-header {
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.infoHeader {
  font-size: 18px;
}

.desk-cancel-button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
</style>
