<template>
  <BaseCard>
    <ProgressSpinner v-if="isLoading" class="p-d-flex p-jc-center" />
    <div class="birthdayListHeader">Geburtstagsliste</div>
    <DataTable scrollable :value="birthdayList">
      <Column field="name" header="Name">
        <template #body="slotProps">
          {{ getEmployeeNameFromIdArray(slotProps.data.name, employees) }}
        </template>
      </Column>
      <Column field="date_of_birth" header="Geburtstag">
        <template #body="slotProps">
          {{ getDateFormattedSimple(slotProps.data.date_of_birth) }}
        </template>
      </Column>
    </DataTable>
  </BaseCard>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import ProgressSpinner from "primevue/progressspinner";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import BaseCard from "@/ui/BaseCard.vue";
import store from "@/store";
import erpnextApi from "@/rest/ErpnextApi.ts";
import { erpNextBirthdays } from "@/data-types";
import { getEmployeeNameFromIdArray } from "@/utils/Todo";
import { getDateFormattedSimple } from "@/features/birthday/utils/Birthday";

export default defineComponent({
  name: "BirthdayList",
  components: { BaseCard, ProgressSpinner, Column, DataTable },
  setup() {
    const isLoading = ref(true);
    const employees = computed(() => store.getters.getAllEmployeesAsMap);
    const birthdays = ref<erpNextBirthdays>();
    const birthdayList = ref();

    onMounted(async () => {
      await store
        .dispatch("fetchAllEmployees")
        .then(async () => {
          await fetchBirthdays();
          isLoading.value = false;
        })
        .catch(() => (isLoading.value = true));
    });

    async function fetchBirthdays() {
      isLoading.value = true;
      await erpnextApi.getBirthdays().then((response) => {
        birthdays.value = response;
        birthdayList.value = birthdays.value;
        birthdayList.value.sort((a: any, b: any) => {
          const aDateParts = a.date_of_birth.split("-");
          const bDateParts = b.date_of_birth.split("-");
          const aMonth = parseInt(aDateParts[1]);
          const bMonth = parseInt(bDateParts[1]);
          const aDay = parseInt(aDateParts[2]);
          const bDay = parseInt(bDateParts[2]);

          if (aMonth < bMonth) return -1;
          if (aMonth > bMonth) return 1;
          if (aDay < bDay) return -1;
          if (aDay > bDay) return 1;
          return 0;
        });
      });
    }

    return {
      getEmployeeNameFromIdArray,
      isLoading,
      employees,
      birthdayList,
      getDateFormattedSimple,
    };
  },
});
</script>

<style scoped>
.birthdayListHeader {
  font-size: 20px;
}
</style>
