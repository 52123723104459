<template>
  <div class="form-control">
    <label for="date">Zeitraum</label>
    <div v-if="selectedAbsenceType === 'Urlaub'">
      <Calendar
        id="date"
        v-model="v$.dateRange.$model"
        :disabled="isLoading"
        class="full-width"
        date-format="dd.mm.yy"
        selection-mode="range"
        :manual-input="false"
        placeholder="Gebe einen Zeitraum ein"
        :class="{ 'p-invalid': v$.dateRange.$invalid && submitted }"
        @month-change="onMonthChange"
      >
        <template #date="slotProps">
          <strong v-if="currentPublicHolidays.find((publicHoliday) => isPublicHoliday(publicHoliday, slotProps.date.day))" class="calendarPublicHoliday">
            {{ slotProps.date.day }}
          </strong>
          <strong v-else-if="currentPublicHolidays.find((publicHoliday) => isWeekend(publicHoliday, slotProps.date.day))" class="calendarWeekend">
            {{ slotProps.date.day }}
          </strong>
          <template v-else>
            {{ slotProps.date.day }}
          </template>
        </template>
      </Calendar>
      <small v-if="v$.dateRange.$invalid && submitted" class="p-error">
        {{ v$.dateRange.required.$message }}
      </small>
    </div>
    <div v-else>
      <Calendar
        id="date"
        v-model="illnessv$.dateRange.$model"
        :class="{ 'p-invalid': illnessv$.dateRange.$invalid && submitted }"
        :disabled="isLoading"
        :manual-input="false"
        class="full-width"
        date-format="dd.mm.yy"
        placeholder="Gebe einen Zeitraum ein"
        selection-mode="range"
        @month-change="onMonthChange"
      >
        <template #date="slotProps">
          <strong v-if="currentPublicHolidays.find((publicHoliday) => isPublicHoliday(publicHoliday, slotProps.date.day))" class="calendarPublicHoliday">
            {{ slotProps.date.day }}
          </strong>
          <strong v-else-if="currentPublicHolidays.find((publicHoliday) => isWeekend(publicHoliday, slotProps.date.day))" class="calendarWeekend">
            {{ slotProps.date.day }}
          </strong>
          <template v-else>
            {{ slotProps.date.day }}
          </template>
        </template>
      </Calendar>
      <small v-if="illnessv$.dateRange.$invalid && submitted" class="p-error">
        {{ illnessv$.dateRange.required.$message }}
      </small>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, reactive, Ref, ref } from "vue";
import { CalendarPublicHoliday } from "@/data-types";
import { getMonthFromDate, getYearFromDate } from "@/utils/Helper";
import Calendar, { CalendarMonthChangeEvent } from "primevue/calendar";
import { useStore } from "vuex";
import { validate } from "@/keys";
import { isPublicHoliday, isWeekend } from "@/utils/HolidayNameGrabber";
import { padNumberWithZero } from "@/utils/UiUtils.ts";
import { Validation } from "@vuelidate/core";

interface Props {
  submitted: boolean;
  isLoading: boolean;
  selectedAbsenceType: string;
}

withDefaults(defineProps<Props>(), {
  submitted: false,
  isLoading: false,
  selectedAbsenceType: "",
});
const v$ = inject(validate, ref<Validation>({} as Validation));
const illnessv$: Ref<Validation> = inject("illnessValidate", ref<Validation>({} as Validation));
const store = useStore();
const today = new Date();

const publicHolidays = computed(() => store.getters.getPublicHolidays);
const currentPublicHolidays = ref<CalendarPublicHoliday[]>([]);
const currentRange = reactive({
  month: getMonthFromDate(today),
  year: getYearFromDate(today),
});

async function fetchCurrentPublicHolidays() {
  await store.dispatch("fetchPublicHolidays", { month: currentRange.month, year: currentRange.year });
  currentPublicHolidays.value = publicHolidays.value.get(currentRange.month + currentRange.year);
}

fetchCurrentPublicHolidays();

function onMonthChange(evt: CalendarMonthChangeEvent) {
  currentPublicHolidays.value = [];
  currentRange.year = evt.year.toString();
  currentRange.month = padNumberWithZero(evt.month);
  fetchCurrentPublicHolidays();
}
</script>

<style scoped>
label {
  font-weight: bold;
  display: block;
  width: 100%;
  margin-bottom: 0.25rem;
}

.calendarPublicHoliday {
  color: var(--lightpink);
}

.calendarWeekend {
  color: var(--calendar-weekend);
}
</style>
