<template>
  <div>
    <input
      ref="hourHtmlInputElement"
      :disabled="disabled"
      :step="1"
      :max="hourInput.max"
      :min="hourInput.min"
      :required="!canClear"
      :tabindex="disabled ? -1 : 0"
      :value="hourInput.timeValue"
      class="time-number-input"
      placeholder="--"
      type="number"
      @input.prevent="handleInput(hourInput, hourHtmlInputElement!, $event)"
      @keydown.left.prevent="
        focusNextElement(
          focusPreviousHtmlElementFromList,
          nextTimePickerSettings.queryString,
          nextTimePickerSettings.selector,
          $event.target as HTMLInputElement,
        )
      "
      @keydown.right.prevent="
        focusNextElement(focusNextHtmlElementFromList, nextTimePickerSettings.queryString, nextTimePickerSettings.selector, $event.target as HTMLInputElement)
      "
      @keydown.up.prevent="incTimeInput(hourInput, hourInput.max)"
      @keydown.down.prevent="decTimeInput(hourInput)"
      @keydown.enter.exact.prevent="
        focusNextElement(focusNextHtmlElementFromList, nextTimePickerSettings.queryString, nextTimePickerSettings.selector, $event.target as HTMLInputElement)
      "
      @keydown.shift.enter.exact.prevent="
        focusNextElement(
          focusPreviousHtmlElementFromList,
          nextTimePickerSettings.queryString,
          nextTimePickerSettings.selector,
          $event.target as HTMLInputElement,
        )
      "
      @keydown.exact="preventSpecificKeyInput($event)"
      @focusout.prevent="setTimeString"
      @focus.prevent="focusHTMLInputElementWithLimitedSelectionCapabilities($event.target)"
      @click.prevent="focusHTMLInputElementWithLimitedSelectionCapabilities($event.target)"
      @mousedown.prevent="focusHTMLInputElementWithLimitedSelectionCapabilities($event.target)"
      @select.prevent
      @scroll.prevent
      @keydown.tab.prevent="focusNextElement()"
      @keydown.shift.tab.prevent.exact="focusNextElement(focusPreviousHtmlElementFromList)"
    />
    <span class="time-delimiter-symbol">:</span>
    <input
      ref="minuteHtmlInputElement"
      :disabled="disabled"
      :max="minuteInput.max"
      :min="minuteInput.min"
      :step="1"
      :required="!canClear"
      :tabindex="disabled ? -1 : 0"
      :value="minuteInput.timeValue"
      class="time-number-input"
      placeholder="--"
      type="number"
      @input.prevent="handleInput(minuteInput, minuteHtmlInputElement!, $event)"
      @keydown.left.prevent="
        focusNextElement(
          focusPreviousHtmlElementFromList,
          nextTimePickerSettings.queryString,
          nextTimePickerSettings.selector,
          $event.target as HTMLInputElement,
        )
      "
      @keydown.right.prevent="
        focusNextElement(focusNextHtmlElementFromList, nextTimePickerSettings.queryString, nextTimePickerSettings.selector, $event.target as HTMLInputElement)
      "
      @keydown.up.prevent="incTimeInput(minuteInput, minuteInput.max)"
      @keydown.down.prevent="decTimeInput(minuteInput)"
      @keydown.enter.exact.prevent="
        focusNextElement(focusNextHtmlElementFromList, nextTimePickerSettings.queryString, nextTimePickerSettings.selector, $event.target as HTMLInputElement)
      "
      @keydown.shift.enter.exact.prevent="
        focusNextElement(
          focusPreviousHtmlElementFromList,
          nextTimePickerSettings.queryString,
          nextTimePickerSettings.selector,
          $event.target as HTMLInputElement,
        )
      "
      @keydown.exact="preventSpecificKeyInput($event)"
      @focusout.prevent="setTimeString"
      @focus.prevent="focusHTMLInputElementWithLimitedSelectionCapabilities($event.target)"
      @click.prevent="focusHTMLInputElementWithLimitedSelectionCapabilities($event.target)"
      @mousedown.prevent="focusHTMLInputElementWithLimitedSelectionCapabilities($event.target)"
      @select.prevent
      @scroll.prevent
      @keydown.tab.prevent="focusNextElement()"
      @keydown.shift.tab.prevent.exact="focusNextElement(focusPreviousHtmlElementFromList)"
    />
  </div>
</template>

<script setup lang="ts">
import { nextTick, onMounted, ref, watch } from "vue";
import { NumberInputReactiveObject, useDesktopTimePicker } from "@/features/weekly/utils/UseDesktopTimePicker.ts";
import { focusNextElement, focusNextHtmlElementFromList, focusPreviousHtmlElementFromList } from "@/utils/Helper.ts";

interface Props {
  disabled?: boolean;
  autofocus?: boolean;
  canClear?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  autofocus: false,
  canClear: false,
});
const timeString = defineModel<string | undefined>();

const hourHtmlInputElement = ref<HTMLInputElement | null>(null);
const minuteHtmlInputElement = ref<HTMLInputElement | null>(null);

const {
  hourInput,
  minuteInput,
  setHasChanged,
  setHourAndMinuteToTimeString,
  resetTimeInput,
  hasTimeInputChanged,
  incTimeInput,
  decTimeInput,
  preventSpecificKeyInput,
  shouldJumpToNextInput,
  resetTimePickerCounter,
  parseTimeValue,
  concatenateNewValue,
  processTimeInput,
} = useDesktopTimePicker();

const nextTimePickerSettings = {
  queryString: ['.time-number-input[type="number"][tabindex]:not([disabled]):not([tabindex="-1"])'].join(","),
  selector: ".dayColumn",
};

watch(
  () => hourInput.timeValue,
  (newHourTime) => setSiblingTimeInputOnChange(minuteInput, newHourTime),
);

watch(
  () => minuteInput.timeValue,
  (newMinuteTime) => setSiblingTimeInputOnChange(hourInput, newMinuteTime),
);

watch(timeString, (newTimeStringValue) => {
  setHourAndMinuteToTimeString(newTimeStringValue);
  setHasChanged(false);
});

onMounted(() => {
  if (props.autofocus) {
    nextTick(() => {
      if (isIosGreater14()) moveScrollPanelToRight();
      else focusHtmlInputElement(hourHtmlInputElement.value);
    });
  }
  setHourAndMinuteToTimeString(timeString.value);
  setHasChanged(false);
});

function setSiblingTimeInputOnChange(timeInputToChange: NumberInputReactiveObject, newInsertedTimeValue: string | null | undefined) {
  if (newInsertedTimeValue == undefined) timeInputToChange.timeValue = null;
  else if (timeInputToChange.timeValue == undefined) resetTimeInput(timeInputToChange);
}

function setTimeString(): void {
  if (hourInput.timeValue == undefined || minuteInput.timeValue == undefined || !hasTimeInputChanged()) return;
  timeString.value = `${hourInput.timeValue}:${minuteInput.timeValue}`;
}

function focusHTMLInputElementWithLimitedSelectionCapabilities(target: EventTarget | null): void {
  const htmlInputElement = target as HTMLInputElement | null;
  if (!htmlInputElement) return;
  resetTimePickerCounter();
  htmlInputElement.type = "text";
  const length = htmlInputElement.value.length;
  htmlInputElement.setSelectionRange(length, length);
  htmlInputElement.type = "number";
  htmlInputElement.focus();
}

function focusHtmlInputElement(htmlInputElement: HTMLInputElement | null, blurHtmlElement?: HTMLInputElement | null | EventTarget): void {
  (blurHtmlElement as HTMLInputElement | null | undefined)?.blur();
  htmlInputElement?.focus();
}

async function handleInputFocus(timeInput: NumberInputReactiveObject, inputElement: HTMLInputElement) {
  if (timeInput.timeValue == undefined) return;
  if (shouldJumpToNextInput(timeInput))
    await focusNextElement(focusNextHtmlElementFromList, nextTimePickerSettings.queryString, nextTimePickerSettings.selector, inputElement);
}

async function handleInput(timeInput: NumberInputReactiveObject, inputElement: HTMLInputElement, event: Event) {
  const insertedNumber = (event as InputEvent).data;
  timeInput.countedInputs++;

  const previousTimeValue = parseTimeValue(timeInput.timeValue);
  const newUnformattedTimeValue = concatenateNewValue(previousTimeValue, insertedNumber);

  timeInput.timeValue = null; // required to force rerender of input element
  processTimeInput(timeInput, newUnformattedTimeValue);

  setHasChanged(true);
  await handleInputFocus(timeInput, inputElement);
}

function moveScrollPanelToRight() {
  const scrollPanelContent = document.getElementsByClassName("p-scrollpanel-content")[0];
  const dayColumn = document.getElementsByClassName("dayColumn");
  const today = new Date();
  for (let i = 0; i <= today.getDay(); i++) {
    scrollPanelContent.scrollLeft += dayColumn[i].clientWidth;
  }
  scrollPanelContent.scrollLeft -= dayColumn[0].clientWidth;
}

function isIosGreater14() {
  const platform = navigator.userAgent;
  const intPlatform = parseInt(platform.slice(35, 37));
  return intPlatform >= 14;
}
</script>

<style scoped>
* {
  --font-size: 14px;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.time-number-input {
  background-color: var(--dark);
  height: 2rem;
  width: 2rem;
  -webkit-appearance: none; /* Chrome, Safari, Edge */
  appearance: none;
  -moz-appearance: textfield; /* For Firefox */
  border: none;
  text-align: center;
  caret-color: transparent;
  border-radius: var(--border-radius);
  transition: box-shadow 75ms linear;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  -moz-user-select: none;
  user-select: none; /* Standard syntax */
}

.time-number-input::placeholder {
  opacity: 1;
}

.time-number-input:focus {
  outline: none;
  box-shadow: inset 0 0 0px 1.5px var(--primary);
  z-index: 9999999;
}

.time-number-input::selection {
  background-color: transparent;
}

.time-number-input:disabled,
.time-number-input:disabled + .time-delimiter-symbol {
  color: var(--grey);
}

.time-delimiter-symbol {
  font-size: calc(var(--font-size) + var(--font-size) / 3);
}

.time-number-input,
.time-number-input:placeholder-shown + .time-delimiter-symbol {
  font-size: var(--font-size);
}
</style>
