<template>
  <input
    id="time-input-id"
    ref="timeHTMLInputElement"
    v-model="time"
    style="display: block; width: 100%"
    :disabled="disabled"
    :required="!canClear"
    :tabindex="disabled ? -1 : undefined"
    class="time-input"
    type="time"
    @keydown="onKeydown"
  />
</template>

<script setup lang="ts">
import { nextTick, ref } from "vue";

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
  canClear: {
    type: Boolean,
    default: false,
  },
});

const time = defineModel<string>();
const timeHTMLInputElement = ref<HTMLInputElement | null>(null);

function findHTMLInputElement(currentTarget: HTMLElement | null): HTMLInputElement | null {
  if (!currentTarget) return null;
  if (currentTarget.nodeName === "INPUT" && !(currentTarget as HTMLInputElement).disabled) return currentTarget as HTMLInputElement;

  for (const child of currentTarget.children) {
    const subChild = findHTMLInputElement(child as HTMLElement);
    if (subChild) return subChild;
  }
  return null;
}

function moveScrollPanelToRight() {
  const scrollPanelContent = document.getElementsByClassName("p-scrollpanel-content")[0];
  const dayColumn = document.getElementsByClassName("dayColumn");
  const today = new Date();
  for (let i = 0; i <= today.getDay(); i++) {
    scrollPanelContent.scrollLeft += dayColumn[i].clientWidth;
  }
  scrollPanelContent.scrollLeft -= dayColumn[0].clientWidth;
}

function isIosGreater14() {
  let platform = navigator.userAgent;
  platform = platform.slice(35, 37);
  const intPlatform = parseInt(platform);
  return intPlatform >= 14;
}

if (props.autofocus) {
  if (isIosGreater14()) nextTick(() => moveScrollPanelToRight());
  else nextTick(() => timeHTMLInputElement.value?.focus());
}

const onKeydown = (e: KeyboardEvent) => {
  if (e.key === "Delete") {
    time.value = "00:00";
  }
  if (e.key === "Enter") {
    let targetParentHtmlElement = (e.target as HTMLElement).parentElement;
    while (targetParentHtmlElement) {
      targetParentHtmlElement = targetParentHtmlElement.nextElementSibling as HTMLElement | null;
      if (!targetParentHtmlElement) break;
      const childHTMLInputElement = findHTMLInputElement(targetParentHtmlElement);
      if (childHTMLInputElement) {
        childHTMLInputElement.focus();
        break;
      }
    }
    e.preventDefault();
  }
};
</script>

<style scoped>
input {
  display: block;
  background: none;
  height: 100%;
  border: none;
  text-align: center;
  width: 6rem;
}

input:disabled {
  color: var(--grey);
}

input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(0);
}
</style>
