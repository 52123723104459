<template>
  <BaseCard>
    <ConfirmDialog></ConfirmDialog>
    <ProgressSpinner v-if="isLoading" class="p-d-flex p-jc-center" />
    <div v-if="!isLoading" class="visitor-container">
      <div class="visitor-header">
        <div v-if="visitorTableDatas.length <= 0" id="no-visitors">Bisher keine Besucher</div>
        <div v-else id="visitor" class="infoHeader">Besucher im Büro</div>
        <Button @click="isPreregisterDialogVisible = !isPreregisterDialogVisible">Vorab anmelden</Button>
        <DialogPreregisterVisitor
          v-model:dialog-visibility="isPreregisterDialogVisible"
          @pre-registered-successfully="showPreRegisterSuccess"
          @pre-register-failed="showPreRegisterError"
        ></DialogPreregisterVisitor>
      </div>
      <DataTable v-if="visitorTableDatas.length > 0" :value="visitorTableDatas" class="visitor-table" scrollable>
        <Column field="date" header="Datum" sortable>
          <template #body="slotProps">
            {{ convertDateToGerDateString(convertDateStringToDate(slotProps.data.erpNextVisitorLog.date)) }}
          </template>
        </Column>
        <Column field="date" header="Start">
          <template #body="slotProps">
            {{ convertDateToGerTimeString(convertDateStringToDate(slotProps.data.erpNextVisitorLog.date)) }}
          </template>
        </Column>
        <Column field="erpNextVisitorLog.visitor_name" header="Name" sortable />
        <Column field="erpNextVisitorLog.company_name" header="Firma" sortable />
        <Column field="erpNextVisitorLog.email" header="E-Mail" />
        <Column field="erpNextVisitorLog.phone" header="Telefon" />
        <Column field="erpNextVisitorLog.reason" header="Grund" />
        <Column field="erpNextVisitorLog.left" header="An-/Abmeldung">
          <template #body="slotProps">
            <span v-if="slotProps.data.erpNextVisitorLog.left">
              {{ convertDateToGerDateString(convertDateStringToDate(slotProps.data.erpNextVisitorLog.left)) }}
              {{ convertDateToGerTimeString(convertDateStringToDate(slotProps.data.erpNextVisitorLog.left)) }}
            </span>
            <div v-else-if="slotProps.data.erpNextVisitorLog.registration_code">
              <InputGroup>
                <InputGroupAddon v-tooltip="'Anmeldecode des Besuchenden zur Registrierung'" class="code-display">
                  {{ slotProps.data.erpNextVisitorLog.registration_code }}
                </InputGroupAddon>
                <Button
                  v-tooltip="'Anmeldecode in die Zwischenablage kopieren'"
                  class="code-copy"
                  icon="pi pi-copy"
                  outlined
                  type="button"
                  @click="copyCodeToClipboard(slotProps.data.erpNextVisitorLog.registration_code)"
                />
              </InputGroup>
            </div>
            <Button v-else class="logout-button" @click="initLogoutSequence(slotProps.data.erpNextVisitorLog)">
              <span>Abmelden</span>
            </Button>
          </template>
        </Column>
        <Column header="WLAN Voucher">
          <template #body="slotProps">
            <span v-if="slotProps.data.voucherCode">
              <InputGroup>
                <InputGroupAddon v-tooltip="'WLAN Voucher des Besuchers'" class="code-display">
                  {{ slotProps.data.voucherCode }}
                </InputGroupAddon>
                <Button
                  v-tooltip="'Voucher in die Zwischenablage kopieren'"
                  class="code-copy"
                  icon="pi pi-copy"
                  outlined
                  type="button"
                  @click="copyCodeToClipboard(slotProps.data.voucherCode)"
                />
              </InputGroup>
            </span>
            <div v-else>
              <Button
                v-if="!slotProps.data.erpNextVisitorLog.left"
                data-testid="create-voucher-button"
                class="generate-button"
                @click="onGenerateVoucher(slotProps.data)"
              >
                <div v-if="!slotProps.data.isVoucherCodeLoading" class="button-content">Generieren</div>
                <ProgressSpinner v-else class="p-d-flex p-jc-center spinner-wrapper generate-button" />
              </Button>
            </div>
          </template>
        </Column>
        <Column header="E-Mail">
          <template #body="slotProps">
            <span v-if="!slotProps.data.erpNextVisitorLog.email_send">
              <Button data-testid="send-visitor-code-as-mail-button" class="generate-button" @click="onSendVisitorCodeAsMail(slotProps.data)">
                <div v-if="!slotProps.data.isEmailSendLoading" class="button-content">Senden</div>
                <ProgressSpinner v-else class="p-d-flex p-jc-center spinner-wrapper generate-button" />
              </Button>
            </span>
            <div v-else data-testid="information-message">
              <span>E-Mail wurde gesendet</span>
            </div>
          </template>
        </Column>
      </DataTable>
      <base-pop-up v-if="entryToConfirm" :pop-up-message="`Den Besucher ${entryToConfirm.visitor_name} abmelden?`">
        <template #content>
          <div class="base-pop-up-content">
            <span>Zeitpunkt der Abmeldung:</span>
            <TimePicker v-model="timeString" :autofocus="true" :can-clear="true" :disabled="false" class="time-input" />
          </div>
        </template>
        <Button outlined @click="entryToConfirm = undefined">Nein</Button>
        <Button data-testid="confirm-button" @click="confirmVisitorLogout">Ja</Button>
      </base-pop-up>
    </div>
  </BaseCard>
</template>

<script setup lang="ts">
export interface VisitorTableData {
  erpNextVisitorLog: ErpNextVisitorLog;
  voucherCode: string;
  isVoucherCodeLoading: boolean;
  isEmailSendLoading: boolean;
}

import BaseCard from "@/ui/BaseCard.vue";
import { ErpNextVisitorLog, VoucherType, WifiVoucherCreationDto } from "@/data-types";
import { useToast } from "primevue/usetoast";
import BasePopUp from "@/ui/BasePopUp.vue";
import { onMounted, ref, watch } from "vue";
import ProgressSpinner from "primevue/progressspinner";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import { showErrorToast, showSuccessToast } from "@/utils/Toast";
import {
  convertDateStringToDate,
  convertDateToGerDateString,
  convertDateToGerTimeString,
  formatToLocalDate,
  isCurrentDateInRange,
  sendVisitorCodeAsMail,
} from "@/features/visitor/utils/VisitorUtil.ts";
import TimePicker from "@/ui/TimePicker.vue";
import DialogPreregisterVisitor from "@/features/visitor/components/DialogPreregisterVisitor.vue";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import erpnextApi from "@/rest/ErpnextApi.ts";
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from "primevue/confirmdialog";

const confirm = useConfirm();
const toast = useToast();
const visitorLog = ref<ErpNextVisitorLog[]>([]);
const visitorTableDatas = ref<VisitorTableData[]>([]);

const isLoading = ref(true);
const entryToConfirm = ref<ErpNextVisitorLog>();
const datetime24h = ref<Date>();
const isPreregisterDialogVisible = ref(false);
const timeString = ref<string>("");

watch(
  timeString,
  (newTimeString) => {
    if (!newTimeString) {
      datetime24h.value = undefined;
      return;
    }
    const [hours, minutes] = newTimeString.split(":").map(Number);
    if (isNaN(hours) || isNaN(minutes) || !entryToConfirm.value) return;
    const newTimestamp = new Date().setHours(hours, minutes);
    if (!isCurrentDateInRange(newTimestamp, entryToConfirm.value.date)) {
      datetime24h.value = new Date();
      timeString.value = convertDateToGerTimeString(datetime24h.value);
    } else datetime24h.value = new Date(newTimestamp);
  },
  { flush: "post" },
);

async function generateVoucher(visitorTableData: VisitorTableData) {
  visitorTableData.isVoucherCodeLoading = true;
  const reason = "Besucher: " + visitorTableData.erpNextVisitorLog.visitor_name + " Grund: " + visitorTableData.erpNextVisitorLog.reason;
  const wifiVoucherCreationDto: WifiVoucherCreationDto = {
    reason: reason,
    voucher_type: VoucherType.GUEST,
  };
  try {
    visitorTableData.voucherCode = await erpnextApi.createVoucherCode(wifiVoucherCreationDto);
  } catch (e: any) {
    showErrorToast(toast, e.message);
  } finally {
    visitorTableData.isVoucherCodeLoading = false;
  }
}

function onGenerateVoucher(visitorTableData: VisitorTableData) {
  confirm.require({
    message: `Mit 'JA' wird für ${visitorTableData.erpNextVisitorLog.visitor_name} ein Voucher erstellt, möchtest du das?`,
    header: "Bestätigung",
    icon: "pi pi-exclamation-triangle",
    rejectClass: "p-button-secondary p-button-outlined",
    rejectLabel: "Abbrechen",
    acceptLabel: "Ja",
    accept: () => {
      generateVoucher(visitorTableData);
    },
  });
}

function onSendVisitorCodeAsMail(visitorTableData: VisitorTableData) {
  confirm.require({
    message: `Mit 'JA' wird der Besuchercode an die E-Mail: ${visitorTableData.erpNextVisitorLog.email} gesendet, möchtest du das?`,
    header: "Bestätigung",
    icon: "pi pi-exclamation-triangle",
    rejectClass: "p-button-secondary p-button-outlined",
    rejectLabel: "Abbrechen",
    acceptLabel: "Ja",
    accept: () => {
      sendVisitorCodeAsMail(visitorTableData, toast);
    },
  });
}

function initLogoutSequence(_erpNextVisitorLog: ErpNextVisitorLog) {
  datetime24h.value = undefined;
  timeString.value = "";
  entryToConfirm.value = _erpNextVisitorLog;
}

function getVisitorLogs() {
  isLoading.value = true;
  erpnextApi
    .getVisitorLogs()
    .then((res: ErpNextVisitorLog[]) => {
      visitorLog.value = res;
      visitorTableDatas.value = res.map((log) => ({
        erpNextVisitorLog: log,
        voucherCode: "",
        isVoucherCodeLoading: false,
        isEmailSendLoading: false,
      }));
      isLoading.value = false;
    })
    .catch((err: Error) => showErrorToast(toast, err.message));
}

async function confirmVisitorLogout() {
  if (!entryToConfirm.value) {
    showErrorToast(toast, "Fehler: Keinen Besucher ausgewählt!");
    return;
  }
  isLoading.value = true;
  if (typeof entryToConfirm.value.date === "string") entryToConfirm.value.date = convertDateStringToDate(entryToConfirm.value.date);
  if (!isCurrentDateInRange(datetime24h.value, entryToConfirm.value.date)) datetime24h.value = undefined;
  await erpnextApi
    .logoutVisitor(entryToConfirm.value.name, formatToLocalDate(datetime24h.value))
    .then(() => {
      showSuccessToast(toast, "Besucher erfolgreich abgemeldet");
      getVisitorLogs();
    })
    .catch((err: Error) => showErrorToast(toast, err.message))
    .finally(() => {
      isLoading.value = false;
      entryToConfirm.value = undefined;
    });
}

function showPreRegisterSuccess(toastMsg: string) {
  isPreregisterDialogVisible.value = false;
  showSuccessToast(toast, toastMsg);
  getVisitorLogs();
}

function showPreRegisterError(toastMsg: string) {
  showErrorToast(toast, toastMsg);
}

onMounted(() => {
  getVisitorLogs();
});

function copyCodeToClipboard(code: string) {
  navigator.clipboard.writeText(code);
  showSuccessToast(toast, `Code "${code}" in die Zwischenablage kopiert`);
}
</script>

<style scoped>
.visitor-container {
  overflow: auto;
}

.visitor-header {
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.infoHeader {
  font-size: 18px;
}

.code-display {
  flex: 1;
  color: var(--white);
  border-color: var(--borderColor);
}

.code-copy {
  border-color: var(--borderColor) !important;
}

.logout-button {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.base-pop-up-content {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 12px;
}

.generate-button {
  width: 110px;
  height: 39px;
}

.time-input {
  padding: 11px;
  border-radius: var(--border-radius);
  border: 1px solid;

  &:focus-visible {
    outline: unset;
  }
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; /* Ensures content uses full button width */
}
</style>
