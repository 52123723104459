import { Employee } from "@/data-types";

export function getEmployeeNameFromId(id: string | undefined, employees: Employee[]) {
  if (id === undefined || id === null) {
    return "";
  }
  const createdByEmployee = employees.filter((employee: Employee) => employee.name === id);
  return createdByEmployee[0].full_name;
}

export function getEmployeeNameFromIdArray(id: string | undefined, employees: Employee[]) {
  if (id === undefined || id === null) {
    return "";
  }
  const employeesArray = Array.from(employees.values());
  const createdByEmployee = employeesArray.filter((employee: Employee) => employee.name === id);
  return createdByEmployee.length > 0 ? createdByEmployee[0].full_name : "--";
}
