<template>
  <base-widget>
    <ProgressSpinner v-if="!loadedContent" class="p-d-flex p-jc-center" />
    <div v-if="loadedContent" class="moodContainer">
      <div class="moodCaption">Durchschnittlich</div>
      <span :style="`font-size: 3rem; color: ${getTextColor()}`">{{ daysInOffice.toFixed(2) }}</span>
      <div class="moodCaption">Tage pro Woche im Büro</div>
    </div>
  </base-widget>
</template>

<script setup lang="ts">
import { ref } from "vue";
import erpnextApi from "@/rest/ErpnextApi.ts";
import { erpNextDaysInOfficeStats } from "@/data-types.ts";
import BaseWidget from "@/ui/BaseWidget.vue";

const loadedContent = ref(false);
const daysInOffice = ref(0);

async function DaysInOfficeStats() {
  await erpnextApi.getDaysInOfficeStats().then((res: erpNextDaysInOfficeStats) => {
    daysInOffice.value = res.avg_office_days;
    loadedContent.value = true;
  });
}

function getTextColor() {
  if (daysInOffice.value <= 0.5) {
    return "#d32f2f";
  } else if (daysInOffice.value < 1) {
    return "#ff9800";
  } else {
    return "var(--primary)";
  }
}

DaysInOfficeStats();
</script>

<style scoped>
.moodContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.moodCaption {
  font-size: 24px;
}
</style>
