export function getDateFormattedSimple(dateString: string) {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const yearString = year.toString();

  const outputString = dateString.replace("XX", yearString);
  const date = new Date(outputString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");

  return `${day}.${month}.`;
}
