<template>
  <BaseCard class="maxWidth">
    <span>
      <TabMenu :model="items" :active-index="activeIndex" />
      <router-view v-slot="{ Component }">
        <transition mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </span>
  </BaseCard>
</template>

<script setup lang="ts">
import BaseCard from "@/ui/BaseCard.vue";
import { useRouter } from "vue-router";
import TabMenu from "primevue/tabmenu";
import { ref } from "vue";

const router = useRouter();
const activeIndex = ref(router.currentRoute.value.path === "/organization/inventory" ? 1 : 0);
const items = [
  { label: "Organigram", icon: "pi pi-fw pi-building", command: () => router.push("/organization") },
  { label: "Inventar", icon: "pi pi-fw pi-cart-plus", command: () => router.push("/organization/inventory") },
  { label: "Geburtstage", icon: "pi pi-fw pi-gift", command: () => router.push("/organization/birthday") },
];
</script>
