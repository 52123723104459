<template>
  <ProfileForm :is-changed="hasChanges" @save="save(!v$.$invalid)">
    <ProfileFormHeader class="p-mt-5" label="Bankkonto" />
    <ProfileFormField label="Name" width="12" identifier="bank_name" :submitted="submitted" :is-changed="hasChanges" @save="save(!v$.$invalid)" />
    <ProfileFormField label="IBAN" width="12" identifier="bank_ac_no" :submitted="submitted" :is-changed="hasChanges" @save="save(!v$.$invalid)" />

    <ProfileFormHeader class="p-mt-5" label="Krankenkasse" />
    <ProfileFormField
      label="Name"
      width="12"
      identifier="health_insurance_provider"
      :submitted="submitted"
      :is-changed="hasChanges"
      @save="save(!v$.$invalid)"
    />
    <ProfileFormField
      label="Versichertennummer"
      width="12"
      identifier="health_insurance_no"
      :submitted="submitted"
      :is-changed="hasChanges"
      @save="save(!v$.$invalid)"
    />
  </ProfileForm>
</template>

<script lang="ts">
import { computed, defineComponent, inject, provide, ref } from "vue";
import ProfileForm from "@/features/profile/components/ProfileForm.vue";
import ProfileFormField from "@/features/profile/components/ProfileFormField.vue";
import { alphaNum, helpers, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { validateIban } from "@/features/profile/utils/Profile";
import { loading, validate } from "@/keys";
import erpnextApi from "@/rest/ErpnextApi.ts";
import { useToast } from "primevue/usetoast";
import ProfileFormHeader from "@/features/profile/components/ProfileFormHeader.vue";
import clonedeep from "lodash.clonedeep";
import { showErrorToast, showSuccessToast } from "@/utils/Toast";

export default defineComponent({
  name: "ProfileMore",
  components: { ProfileFormHeader, ProfileForm, ProfileFormField },
  setup() {
    const toast = useToast();
    const submitted = ref(false);
    const profile = ref<{
      bank_name: string | null;
      bank_ac_no: string | null;
      health_insurance_provider: string | null;
      health_insurance_no: string | null;
    }>({ bank_name: null, bank_ac_no: null, health_insurance_provider: null, health_insurance_no: null });
    const isLoading = inject(loading, ref(true));
    const initialState = ref({});

    isLoading.value = true;
    Promise.all([erpnextApi.getProfileHealthInsurance(), erpnextApi.getProfileBankAccount()])
      .then(([healthInsuranceRes, bankAccountRes]) => {
        profile.value.health_insurance_no = healthInsuranceRes.health_insurance_no;
        profile.value.health_insurance_provider = healthInsuranceRes.health_insurance_provider;
        profile.value.bank_ac_no = bankAccountRes.bank_ac_no;
        profile.value.bank_name = bankAccountRes.bank_name;
        initialState.value = clonedeep(profile.value);
      })
      .catch((err) => {
        showErrorToast(toast, err as string);
      })
      .finally(() => {
        isLoading.value = false;
      });

    const rules = {
      bank_name: { required: helpers.withMessage("*", required) },
      bank_ac_no: {
        required: helpers.withMessage("*", required),
        validateIban: helpers.withMessage("nicht gültig, bitte überprüfen", validateIban),
      },
      health_insurance_provider: { required: helpers.withMessage("*", required) },
      health_insurance_no: {
        required: helpers.withMessage("*", required),
        alphaNum: helpers.withMessage("Bitte nur Buchstaben & Zahlen eingeben", alphaNum),
      },
    };
    const v$ = useVuelidate(rules, profile);
    provide(validate, v$);

    async function save(isFormValid: boolean) {
      submitted.value = true;
      if (!isFormValid) return;
      isLoading.value = true;

      try {
        await Promise.all([
          erpnextApi.updateProfileBankAccount({
            bank_ac_no: profile.value.bank_ac_no,
            bank_name: profile.value.bank_name,
          }),
          erpnextApi.updateProfileHealthInsurance({
            health_insurance_provider: profile.value.health_insurance_provider,
            health_insurance_no: profile.value.health_insurance_no,
          }),
        ]);
        showSuccessToast(toast, erpnextApi.ProfileUpdatedMessage);
        initialState.value = clonedeep(profile.value);
      } catch (err) {
        showErrorToast(toast, err as string);
      } finally {
        isLoading.value = false;
      }
    }

    const hasChanges = computed(() => {
      return JSON.stringify(profile.value) !== JSON.stringify(initialState.value);
    });

    return { v$, submitted, save, hasChanges };
  },
});
</script>

<style scoped></style>
