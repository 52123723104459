import { ToastServiceMethods } from "primevue/toastservice";

export function showSuccessToast(toast: ToastServiceMethods, message: string) {
  clearAllToastMessages(toast);
  toast.add({ severity: "success", detail: message, group: "br", life: 3600 });
}

export function showErrorToast(toast: ToastServiceMethods, message: string) {
  clearAllToastMessages(toast);
  toast.add({ severity: "error", detail: message, group: "br", life: 7200 });
}

export function clearAllToastMessages(toast: ToastServiceMethods) {
  toast.removeAllGroups();
}
