<template>
  <div v-if="employees.length > 0" class="p-d-flex p-ai-center p-flex-wrap p-mr-2">
    <div v-for="employee in employees" :key="employee.name">
      <TooltipWrapper :content="employee.full_name">
        <EmployeeIcon :employee="employee" />
      </TooltipWrapper>
    </div>
  </div>
</template>
<script setup lang="ts">
import { Employee } from "@/data-types";
import TooltipWrapper from "@/ui/TooltipWrapper.vue";
import EmployeeIcon from "@/ui/EmployeeIcon.vue";

interface Props {
  employees: Employee[];
}

defineProps<Props>();
</script>

<style scoped>
.employee-avatar {
  font-size: small;
  margin: 2px;
}
</style>
