<template>
  <ProgressSpinner v-if="!loadedContent" class="p-d-flex p-jc-center" />
  <div v-if="isEvent" class="eventWidgetContainer">
    <div class="eventTitle">Event im Büro</div>
    <div class="eventText">
      {{ eventMessage }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import erpnextApi from "@/rest/ErpnextApi.ts";
import ProgressSpinner from "primevue/progressspinner";

export default defineComponent({
  name: "EventWidget",
  components: { ProgressSpinner },
  setup() {
    const eventMessage = ref("");
    const date = new Date();
    const workplaceBookings = ref();
    const loadedContent = ref(false);
    const officeNote = ref();
    const isEvent = ref(false);

    async function getEvent() {
      workplaceBookings.value = await erpnextApi.getWorkplaceBookings(date, 1);
      if (workplaceBookings.value?.[0]?.notes?.[0]?.note) {
        officeNote.value = workplaceBookings.value[0].notes[0].note;
        isEvent.value = true;
        eventMessage.value = officeNote.value;
      }
      loadedContent.value = true;
    }

    getEvent();

    return {
      eventMessage,
      officeNote,
      loadedContent,
      isEvent,
    };
  },
});
</script>

<style scoped>
.eventWidgetContainer {
  margin: 1rem;
  padding: 0.5rem;
  background-color: var(--greystep);
  border-radius: 5px;
}

.eventTitle {
  font-size: 24px;
  border-bottom: 1px solid var(--white);
}

.eventText {
  font-size: 23px;
  font-weight: bold;
  margin-top: 1rem;
  color: var(--primary);
}

@media (max-width: 700px) {
  .eventTitle {
    font-size: 20px;
  }

  .eventText {
    font-size: 20px;
  }
}
</style>
