import moment from "moment";
import { YearReportBookingPosition } from "@/data-types";
import { DeepReadonly } from "vue";
import { ChartDataset, LegendItem } from "chart.js";
import { colorFromString } from "@/utils/ColorFromString";

export const chartOptions = {
  responsive: true,
  plugins: {
    tooltip: { callbacks: { label: chartTooltipLabel } },
    legend: { display: true, position: "bottom", labels: { sort: sortLegendItems } },
  },
  scales: {
    y: { ticks: { callback: chartYAxis }, stacked: true, min: 0 },
    x: { stacked: true },
  },
  animations: true,
};

export function chartYAxis(value: any) {
  return value + "h";
}

export function chartTooltipLabel(context: any) {
  return context.dataset.label + ": " + context.parsed.y.toFixed(2) + " Stunden";
}

export function sortLegendItems(a: LegendItem, b: LegendItem) {
  const al = a.text.toLowerCase(),
    bl = b.text.toLowerCase();
  if (al < bl) return -1;
  if (al > bl) return 1;
  return 0;
}

export function createMonthStringArray() {
  return [...Array(12)].map((value, index) => moment.months(index).substring(0, 3));
}

export function createYearlyTable(bookingPositions: ReadonlyArray<DeepReadonly<YearReportBookingPosition>>, months: string[]) {
  return bookingPositions.map((position) => {
    const positionObj = { name: position.name, project: position.project };
    months.forEach((monthValue, index) => {
      Object.assign(positionObj, { [moment.months(index).substring(0, 3)]: position.months[index] });
    });
    return positionObj;
  });
}

export function addNewDataset(dataset: ChartDataset[], position: YearReportBookingPosition) {
  dataset.push({
    data: position.months,
    label: position.name,
    backgroundColor: colorFromString(position.name),
  });
}
