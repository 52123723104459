<template>
  <BaseSideCardWeekly>
    <div class="p-text-bold" style="color: var(--white); padding-bottom: 8px">
      Budgetübersicht
      {{ annualStatement.annual }}
    </div>
    <overview-data-entry :title="'Gesamtstunden'" :value="buildTimeStringFromHours(totalHours)" :is-sub-item="false" />
    <overview-data-entry :title="'Geleistete Stunden'" :value="buildTimeStringFromHours(annualStatement.current_hours)" :is-sub-item="true" />
    <overview-data-entry :title="'Stunden aus Krankheit'" :value="buildTimeStringFromHours(annualStatement.sick_hours)" :is-sub-item="true" />
    <overview-data-entry :title="'Ausgezahlte Stunden'" :value="buildTimeStringFromHours(payoutAmount)" :is-sub-item="true" />
    <overview-data-entry :title="'Überstunden aus Vorjahr'" :value="buildTimeStringFromHours(annualStatement.carry_hours)" :is-sub-item="true" />
    <overview-data-entry :title="'Verbleibende Stunden'" :value="buildTimeStringFromHours(annualStatement.left_hours)" :is-sub-item="false" />
    <overview-data-entry :title="'Überstunden'" :value="buildTimeStringFromHours(overtime)" :is-sub-item="true" />
    <overview-data-entry :title="'Geschätzte Überstunden *'" :value="buildTimeStringFromHours(annualStatement.guessed_overtime_hours)" :is-sub-item="true" />
    <span style="font-size: 7pt">* Basierend auf geleisteter Arbeit und Jahresfortschritt</span>
  </BaseSideCardWeekly>
</template>

<script lang="ts">
import Tooltip from "primevue/tooltip";
import { buildTimeStringFromHours } from "@/utils/Helper";
import OverviewDataEntry from "@/ui/OverviewDataEntry.vue";
import BaseSideCardWeekly from "@/ui/BaseSideCardWeekly.vue";
import { computed, PropType } from "vue";
import { AnnualStatement } from "@/data-types";

export default {
  name: "CurrentBudget",
  components: { OverviewDataEntry, BaseSideCardWeekly },
  directives: { tooltip: Tooltip },
  props: {
    annualStatement: {
      type: Object as PropType<AnnualStatement>,
      required: true,
    },
  },
  setup(props: any) {
    const totalHours = computed(() => {
      const currentNormalHours = props.annualStatement.current_hours || 0;
      const currentOvertimeHours = props.annualStatement.current_overtime_hours || 0;

      return currentNormalHours + currentOvertimeHours;
    });

    const overtime = computed(() => {
      const carryNormalHours = props.annualStatement.carry_normal_hours || 0;
      const carryOvertimeHours = props.annualStatement.carry_overtime_hours || 0;

      return carryNormalHours + carryOvertimeHours;
    });

    const payoutAmount = computed(() => {
      if (props.annualStatement.payouts && Array.isArray(props.annualStatement.payouts) && props.annualStatement.payouts.length > 0) {
        const amount = props.annualStatement.payouts[0].amount;
        return amount > 0 ? -amount : amount;
      }
      return 0;
    });

    return {
      buildTimeStringFromHours,
      totalHours,
      overtime,
      payoutAmount,
    };
  },
};
</script>
