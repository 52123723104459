<template>
  <div class="legend">
    <div v-for="(item, index) in legends" :key="index" class="legend-item">
      <span class="legend-color" :style="{ backgroundColor: item.color }" />
      <span class="legend-label">{{ item.label }}</span>
    </div>
  </div>
</template>
<script lang="ts" setup>
interface DeskLegend {
  label: string;
  color: string;
}

const legends: DeskLegend[] = [
  { label: "Frei", color: "var(--free-desk)" },
  { label: "Gebucht", color: "var(--my-desk)" },
  { label: "Fremdbuchung", color: "var(--desk-unavailable)" },
  { label: "Blockiert von mir", color: "var(--desk-blocked-by-user-employee)" },
  { label: "Blockiert von anderen", color: "var(--desk-blocked-by-other)" },
];
</script>

<style scoped>
.legend {
  font-family: Arial, sans-serif;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  margin-right: 1rem;
  padding: 8px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.legend-color {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  margin-right: 8px;
}

.legend-label {
  font-size: 14px;
}
</style>
