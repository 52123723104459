import { erpNextUserProfile } from "@/data-types";
import erpnext from "@/rest/ErpnextApi.ts";
import { RootState } from "@/store";
import { Module } from "vuex";

export interface UserProfileStoreState {
  userProfile: erpNextUserProfile;
}

const userProfileStore: Module<UserProfileStoreState, RootState> = {
  state: () => ({
    userProfile: {} as erpNextUserProfile,
  }),
  getters: {
    getUserProfile(state: UserProfileStoreState) {
      return state.userProfile;
    },
  },
  mutations: {
    setUserProfile(state: UserProfileStoreState, userProfile: erpNextUserProfile) {
      state.userProfile = userProfile;
    },
  },
  actions: {
    async fetchUserProfile(context: any) {
      if (context.state.userProfile == null) {
        context.commit("setUserProfile", await erpnext.getProfileSettings());
      }
    },
    async reloadUserProfile(context: any) {
      context.commit("setUserProfile", await erpnext.getProfileSettings());
    },
  },
};

export default userProfileStore;
