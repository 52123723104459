<template>
  <div v-if="isLoading" style="display: flex; justify-content: center">
    <ProgressSpinner />
  </div>
  <div v-else class="visitor-data-container">
    <div class="header-text">
      <span v-if="!visitorDataList.length" id="no-visitors">Heute keine Besucher</span>
      <span v-else id="visitor">Besucher des Tages</span>
    </div>
    <DataTable v-if="visitorDataList.length" :value="visitorDataList" scrollable class="table-container">
      <Column field="date" header="Start">
        <template #body="{ data }">
          {{ convertDateToGerTimeString(data.visitorLog.date) }}
        </template>
      </Column>
      <Column field="visitorLog.visitor_name" header="Name" sortable />
      <Column field="visitorLog.reason" header="Grund" />
      <Column field="visitorLog.company_name" header="Firma" sortable />
      <Column field="visitorLog.host" header="Host" />
      <Column field="visitorLog.left" header="An-/Abmeldung">
        <template #body="{ data }">
          <span v-if="data.visitorLog.left">
            {{ convertDateToGerDateString(data.visitorLog.left) }}
            {{ convertDateToGerTimeString(data.visitorLog.left) }}
          </span>
          <div v-else-if="data.visitorLog.registration_code">
            <InputGroup>
              <InputGroupAddon v-tooltip="'Anmeldecode des Besuchenden zur Registrierung'" class="code-display">
                {{ data.visitorLog.registration_code }}
              </InputGroupAddon>
              <Button
                v-tooltip="'Anmeldecode kopieren'"
                class="code-copy"
                icon="pi pi-copy"
                outlined
                @click="copyToClipboard(data.visitorLog.registration_code)"
              />
            </InputGroup>
          </div>
          <Button v-else class="logout-button" @click="startLogoutProcess(data.visitorLog)">
            <span>Abmelden</span>
          </Button>
        </template>
      </Column>
    </DataTable>
  </div>

  <BasePopUp v-if="visitorToLogout" :pop-up-message="`Den Besucher ${visitorToLogout.visitor_name} abmelden?`">
    <template #content>
      <div class="base-pop-up-content">
        <span>Zeitpunkt der Abmeldung:</span>
        <TimePicker v-model="logoutTimeString" :autofocus="true" :can-clear="true" :disabled="false" class="time-input" />
      </div>
    </template>
    <Button outlined @click="cancelLogout">Nein</Button>
    <Button data-testid="confirm-button" @click="confirmLogout">Ja</Button>
  </BasePopUp>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import { useToast } from "primevue/usetoast";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import TimePicker from "@/ui/TimePicker.vue";
import BasePopUp from "@/ui/BasePopUp.vue";
import { ErpNextTodayVisitorLog } from "@/data-types";
import erpnextApi from "@/rest/ErpnextApi.ts";
import { showErrorToast, showSuccessToast } from "@/utils/Toast";
import { convertDateToGerDateString, convertDateToGerTimeString, formatToLocalDate, isCurrentDateInRange } from "@/features/visitor/utils/VisitorUtil";
import { useStore } from "vuex";

export interface VisitorTableData {
  visitorLog: ErpNextTodayVisitorLog;
  voucherCode: string;
  isVoucherCodeLoading: boolean;
}

const visitorDataList = ref<VisitorTableData[]>([]);
const visitorToLogout = ref<ErpNextTodayVisitorLog | null>(null);
const logoutTimeString = ref("");
const logoutDateTime = ref<Date | null>(null);
const isLoading = ref(false);

const toast = useToast();
const store = useStore();

watch(logoutTimeString, (newTime) => {
  if (!newTime || !visitorToLogout.value) {
    logoutDateTime.value = null;
    return;
  }
  const [hours, minutes] = newTime.split(":").map(Number);
  if (isNaN(hours) || isNaN(minutes)) return;

  const logoutTime = new Date(visitorToLogout.value.date);
  logoutTime.setHours(hours, minutes, 0, 0);

  if (isCurrentDateInRange(logoutTime, visitorToLogout.value.date)) {
    logoutDateTime.value = logoutTime;
  } else {
    logoutDateTime.value = new Date();
    logoutTimeString.value = convertDateToGerTimeString(logoutDateTime.value);
  }
});

onMounted(fetchVisitorLogs);

async function fetchVisitorLogs() {
  isLoading.value = true;

  try {
    if (!store.getters.getAllEmployeesAsMap.size) await store.dispatch("fetchAllEmployees");
    const logs = await erpnextApi.getTodayVisitors();
    visitorDataList.value = logs.map((log) => {
      log.host = store.getters.getAllEmployeesAsMap.get(log.host)?.full_name ?? log.host;
      return {
        visitorLog: log,
        voucherCode: "",
        isVoucherCodeLoading: false,
      };
    });
  } catch (error: any) {
    showErrorToast(toast, error.message);
  } finally {
    isLoading.value = false;
  }
}

function startLogoutProcess(visitorLog: ErpNextTodayVisitorLog) {
  visitorToLogout.value = visitorLog;
  logoutTimeString.value = "";
  logoutDateTime.value = null;
}

function cancelLogout() {
  visitorToLogout.value = null;
}

async function confirmLogout() {
  if (!visitorToLogout.value) {
    showErrorToast(toast, "Fehler: Keinen Besucher ausgewählt!");
    return;
  }
  isLoading.value = true;
  try {
    const logoutTime = logoutDateTime.value ? formatToLocalDate(logoutDateTime.value) : undefined;
    await erpnextApi.logoutVisitor(visitorToLogout.value.name, logoutTime);
    showSuccessToast(toast, "Besucher erfolgreich abgemeldet");
    await fetchVisitorLogs();
  } catch (error: any) {
    showErrorToast(toast, error.message);
  } finally {
    isLoading.value = false;
    visitorToLogout.value = null;
  }
}

function copyToClipboard(text: string) {
  navigator.clipboard.writeText(text);
  showSuccessToast(toast, `Code "${text}" in die Zwischenablage kopiert`);
}
</script>

<style scoped>
.code-display {
  color: var(--white);
  border-color: var(--borderColor);
}

.code-copy {
  border-color: var(--borderColor) !important;
}

.header-text {
  font-size: 18px;
}

.visitor-data-container {
  max-height: 70vh;
  display: flex;
  flex-flow: column nowrap;
  padding-top: 12px;
  gap: 12px;
}

.table-container {
  overflow: auto;
}
</style>
