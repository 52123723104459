import moment from "moment";
import { startOfMonth } from "@/utils/Helper";

export function foregroundForBackground(hexColor: string) {
  const r = parseInt(hexColor.substring(1, 3), 16);
  const g = parseInt(hexColor.substring(3, 5), 16);
  const b = parseInt(hexColor.substring(5, 7), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
}

export function addAmountOfYears(date: Date, amount: number): Date {
  return moment(startOfMonth(date)).add(amount, "years").toDate();
}

export function padNumberWithZero(num: number): string {
  return String(num).padStart(2, "0");
}
