<template>
  <BaseCard class="maxWidth">
    <span>
      <TabMenu :model="items" :active-index="activeIndex">
        <template #item="{ item, props }">
          <router-link v-slot="{ href, navigate }" :to="item.route">
            <a :href="href" v-bind="props.action" @click="navigate">
              <span v-bind="props.icon" />
              <span v-bind="props.label">{{ item.label }}</span>
            </a>
          </router-link>
        </template>
      </TabMenu>
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </span>
  </BaseCard>
</template>

<script setup lang="ts">
import BaseCard from "@/ui/BaseCard.vue";
import { useRouter } from "vue-router";
import TabMenu from "primevue/tabmenu";
import { computed } from "vue";
import { MenuItem } from "primevue/menuitem";

const router = useRouter();
const activeIndex = computed(() => {
  switch (router.currentRoute.value.path) {
    case "/visitor-overview":
      return 0;
    case "/visitor-overview/visitors":
      return 1;
    case "/visitor-overview/voucher":
      return 2;
    case "/visitor-overview/deskblocking":
      return 3;
    default:
      return 0; // default to first item if no route matches the available ones.
  }
});

const items: MenuItem[] = [
  {
    label: "Heutige Besucher",
    icon: "pi pi-users",
    route: "/visitor-overview",
  },
  {
    label: "Besucher",
    icon: "pi pi-fw pi-building",
    route: "/visitor-overview/visitors",
  },
  {
    label: "WLAN Voucher",
    icon: "pi pi-wifi",
    route: "/visitor-overview/voucher",
  },
  {
    label: "Tisch Blockierung",
    icon: "pi pi-user-edit",
    route: "/visitor-overview/deskblocking",
  },
];
</script>
