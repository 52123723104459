import { CalendarPublicHoliday, PublicHoliday } from "@/data-types";
import erpnextApi from "@/rest/ErpnextApi.ts";
import { Module } from "vuex";
import { sortPublicHolidays } from "@/utils/Helper";
import { RootState } from "@/store";

export interface PublicHolidayStoreState {
  publicHolidays: Map<string, CalendarPublicHoliday[]>;
}

interface MutationsPayload {
  monthAndYear: string;
  publicHolidays: CalendarPublicHoliday[];
}

interface ActionsPayload {
  month: string;
  year: string;
}

const publicHolidayStore: Module<PublicHolidayStoreState, RootState> = {
  state: () => ({
    publicHolidays: new Map<string, CalendarPublicHoliday[]>(),
  }),
  getters: {
    getPublicHolidays(state: PublicHolidayStoreState): Map<string, CalendarPublicHoliday[]> {
      return state.publicHolidays;
    },
  },
  mutations: {
    setPublicHolidays(state: PublicHolidayStoreState, payload: MutationsPayload) {
      state.publicHolidays.set(payload.monthAndYear, payload.publicHolidays);
    },
  },
  actions: {
    async fetchPublicHolidays(context: any, payload: ActionsPayload) {
      if (!context.state.publicHolidays.get(payload.month + payload.year)) {
        const publicHolidays: PublicHoliday[] = await erpnextApi.getPublicHolidays(payload.month, payload.year);
        context.commit("setPublicHolidays", {
          monthAndYear: payload.month + payload.year,
          publicHolidays: sortPublicHolidays(publicHolidays),
        });
      }
    },
  },
};

export default publicHolidayStore;
