import { BookingDay, BookingDayPosition, BookingPosition, BookingPositionDescription } from "@/data-types.ts";
import erpnextApi from "@/rest/ErpnextApi.ts";
import { calculateDayHash, prepareWeek } from "@/features/weekly/utils/Weekly.ts";
import { ref } from "vue";

export function useLoadBookingDataForPeriod() {
  const loadedTimeSheetEntries = [] as BookingDay[];
  const bookingPositionDescriptionByName = ref(new Map<string, BookingPositionDescription>());
  const bookingDayByDayHash = ref(new Map<string, BookingDay>());

  async function loadBookingDataForPeriod(startDate: Date, days: number) {
    await loadBookingPositionDescriptionsForGivenPeriod(startDate, days);
    initializeBookingDayByDayHash();
  }

  return {
    loadBookingDataForPeriod,
    bookingPositionDescriptionByName,
    bookingDayByDayHash,
  };

  /*
   * Load all booking positions which are booked or available to be booked in the given period
   */
  async function loadBookingPositionDescriptionsForGivenPeriod(startDate: Date, days: number) {
    await loadBookingPositionDescriptionNamesForGivenPeriod(startDate, days);
    await loadMissingBookingPositionDescriptions();
  }

  /**
   * Load all booking positions which are booked or available to be booked in the given period
   */
  async function loadBookingPositionDescriptionNamesForGivenPeriod(startDate: Date, days: number) {
    await loadAndStoreTimeSheetEntriesForGivenPeriod(startDate, days);
    const availableBookingPositions: BookingPosition[] = await erpnextApi.getAvailableBookingPositions();

    const bookingPositionDescriptionNames: string[] = Array.from(
      new Set([
        ...loadedTimeSheetEntries.flatMap((x: BookingDay) => x.positionHours).map((x: BookingDayPosition) => x.name),
        ...availableBookingPositions.map((x: BookingPosition) => x.name),
      ]),
    );

    for (const bookingPositionDescriptionName of bookingPositionDescriptionNames) {
      if (!bookingPositionDescriptionByName.value.has(bookingPositionDescriptionName)) {
        bookingPositionDescriptionByName.value.set(bookingPositionDescriptionName, {
          number: "",
          closed: false,
          description: "",
          full_name: "",
          has_comment: false,
          left_hours: 0,
          name: bookingPositionDescriptionName,
          project: "",
          requires_comment: false,
          total_hours: 0,
          used_hours: 0,
          missing: true,
          changeable_from: null,
          changeable_till: null,
          isUserAllowedToBook: true,
        });
      }
    }

    const bookingPositionDescriptionsToDisableBooking = bookingPositionDescriptionNames.filter(
      (name) => !availableBookingPositions.find((x) => x.name === name),
    );
    for (const bookingPositionDescriptionName of bookingPositionDescriptionsToDisableBooking) {
      const bookingPositionDescription = bookingPositionDescriptionByName.value.get(bookingPositionDescriptionName);
      if (bookingPositionDescription) {
        bookingPositionDescription.isUserAllowedToBook = false;
      }
    }
  }

  /**
   * This method is used sort the booking positions
   */
  function initializeBookingDayByDayHash() {
    const week: BookingDay[] = prepareWeek(loadedTimeSheetEntries);

    for (const bookingDay of week) {
      bookingDay.positionHours.sort((x, y) => {
        const bpX = bookingPositionDescriptionByName.value.get(x.name);
        const bpY = bookingPositionDescriptionByName.value.get(y.name);

        if (bpX && bpY) {
          // Sort external projects before internal projects
          if (!bpX.project.startsWith("Intern") && bpY.project.startsWith("Intern")) return -1;
          if (bpX.project.startsWith("Intern") && !bpY.project.startsWith("Intern")) return 1;

          // Sort alphabetically by the project name
          if (bpX.project < bpY.project) return -1;
          if (bpX.project > bpY.project) return 1;

          // Sort alphabetically by the booking position
          if (bpX.description < bpY.description) return -1;
          if (bpX.description > bpY.description) return 1;
        }
        return 0;
      });
      bookingDayByDayHash.value.set(calculateDayHash(bookingDay.date), bookingDay);
    }
  }

  /**
   * This function is used to load the details of the booking descriptions and store them into bookingPositions
   */
  async function loadMissingBookingPositionDescriptions() {
    const bookingPositionDescriptionsToLoad = [];
    for (const description of bookingPositionDescriptionByName.value.values()) {
      if (description.missing) {
        bookingPositionDescriptionsToLoad.push(description.name);
      }
    }
    if (bookingPositionDescriptionsToLoad.length === 0) return;

    const loadedBookPositionDescriptions = await erpnextApi.getBookingPositionDescriptionsByNames(bookingPositionDescriptionsToLoad);
    for (const loadedBookingPositionDescription of loadedBookPositionDescriptions) {
      const bookingPositionDescription = bookingPositionDescriptionByName.value.get(loadedBookingPositionDescription.name);
      if (bookingPositionDescription) {
        bookingPositionDescription.project = loadedBookingPositionDescription.project;
        bookingPositionDescription.closed = loadedBookingPositionDescription.closed;
        bookingPositionDescription.has_comment = loadedBookingPositionDescription.has_comment;
        bookingPositionDescription.description = loadedBookingPositionDescription.description;
        bookingPositionDescription.left_hours = loadedBookingPositionDescription.left_hours;
        bookingPositionDescription.requires_comment = loadedBookingPositionDescription.requires_comment;
        bookingPositionDescription.total_hours = loadedBookingPositionDescription.total_hours;
        bookingPositionDescription.used_hours = loadedBookingPositionDescription.used_hours;
        bookingPositionDescription.changeable_from = loadedBookingPositionDescription.changeable_from;
        bookingPositionDescription.changeable_till = loadedBookingPositionDescription.changeable_till;

        const seperatedName = bookingPositionDescription.name.split("-");
        bookingPositionDescription.number = seperatedName[0] + "-" + seperatedName[1];
        bookingPositionDescription.full_name = bookingPositionDescription.project + ": " + bookingPositionDescription.description;
        bookingPositionDescription.missing = false;
      } else {
        loadedBookingPositionDescription.missing = false;
        bookingPositionDescriptionByName.value.set(loadedBookingPositionDescription.name, loadedBookingPositionDescription);
      }
    }
  }

  async function loadAndStoreTimeSheetEntriesForGivenPeriod(startDate: Date, days: number) {
    const timesheetEntries: BookingDay[] = await erpnextApi.getTimesheetEntriesBetween(startDate, days);
    loadedTimeSheetEntries.length = 0;
    loadedTimeSheetEntries.push(...timesheetEntries);
  }
}
