<template>
  <div class="card">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseCardWeekly",
});
</script>
<style scoped>
.card {
  border-radius: 3px;
  padding: 1rem 0.75rem;
  border: 1px solid var(--cardBorderBolor);
  background-color: var(--dark);
  min-width: 300px;
  width: 100%;
  display: block;
}

@media (min-width: 360px) {
  .card {
    width: 100%;
  }
}

@media (min-width: 460px) {
  .card {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .card {
    width: 100%;
  }
}

@media (min-width: 672px) {
  .card {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .card {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .card {
    width: 50%;
    margin-top: 1rem;
  }
}

@media (min-width: 1120px) {
  .card {
    width: min-content;
  }
}

@media (min-width: 1200px) {
  .card {
    width: min-content;
  }
}

@media (min-width: 1400px) {
  .card {
    width: min-content;
  }
}
</style>
