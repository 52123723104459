<template>
  <div
    ref="tooltip"
    v-tooltip.focus="content"
    tabindex="-1"
    style="width: 100%"
    @[click]="toggleTooltip"
    @[mouseover]="activateTooltip"
    @[mouseleave]="deactivateTooltip"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { isMobile } from "@/utils/Helper";

interface Props {
  content?: string;
}

const props = withDefaults(defineProps<Props>(), {
  content: "",
});
const tooltip = ref();
const tooltipActive = ref(false);

const click = computed(() => (props.content && isMobile() ? "click" : null));
const mouseover = computed(() => (props.content && !isMobile() ? "mouseover" : null));
const mouseleave = computed(() => (props.content && !isMobile() ? "mouseleave" : null));

function toggleTooltip() {
  tooltipActive.value ? deactivateTooltip() : activateTooltip();
}

function activateTooltip() {
  tooltipActive.value = true;
  tooltip.value.focus();
}

function deactivateTooltip() {
  tooltipActive.value = false;
  tooltip.value.blur();
}
</script>

<style>
div:focus {
  outline: none;
}
</style>
