<template>
  <nav v-if="isLoggedIn">
    <ul>
      <router-link to="/" class="pep-logo-hover">
        <li>
          <div v-if="isLocal" class="dev-banner">
            <p>⚠️ {{ branchName }}:{{ commitHash }}</p>
          </div>
          <img src="/assets/icon/logo.png" alt="pep_digital_logo" />
        </li>
      </router-link>
      <template v-if="menuHidden || largeScreen">
        <li>
          <router-link to="/weekly" @click="toggleDropdown"> Zeiterfassung</router-link>
        </li>
        <li>
          <router-link :to="calculateWorkplaceRouterPath()" @click="toggleDropdown"> Arbeitsort</router-link>
        </li>
        <li v-if="!isExternal">
          <router-link to="/absence" @click="toggleDropdown"> Abwesenheit</router-link>
        </li>
        <li v-if="!isExternal">
          <router-link :to="yearlyRoute" @click="toggleDropdown"> Jahresübersicht</router-link>
        </li>
        <li v-if="!isExternal">
          <router-link to="/visitor-overview" @click="toggleDropdown"> Besucher</router-link>
        </li>
        <li v-if="!isExternal">
          <router-link to="/organization" @click="toggleDropdown"> Organisation</router-link>
        </li>

        <ul class="logoutButton">
          <li>
            <router-link to="/profile/settings" @click="toggleDropdown"> Profil</router-link>
          </li>
          <li>
            <router-link to="/logout" @click="logout"> Logout</router-link>
          </li>
        </ul>
      </template>
      <li>
        <a href="javascript:void(0);" class="icon" @click="toggleDropdown"><i class="pi pi-bars" /></a>
      </li>
    </ul>
  </nav>
</template>
<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import erpnext from "@/rest/ErpnextApi.ts";
import { calculateWorkplaceRouterPath } from "@/utils/WorkplacePathCalculator.ts";

const branchName = import.meta.env.VITE_APP_BRANCH_NAME;
const commitHash = import.meta.env.VITE_APP_COMMIT_HASH;
const isLocal = import.meta.env.VITE_APP_IS_LOCAL;
const menuHidden = ref(false);
const router = useRouter();
const store = useStore();
const windowWidth = ref(window.innerWidth);
const isExternal = computed(() => store.getters.isExternal);
const isLoggedIn = computed(() => store.getters.isLoggedIn);
const largeScreen = computed(() => windowWidth.value >= 1229);
const yearlyRoute = computed(() => "/yearly/" + new Date().getFullYear());

onMounted(() => {
  window.addEventListener("resize", onWidthChange);
});
onUnmounted(() => window.removeEventListener("resize", onWidthChange));

function onWidthChange(): void {
  windowWidth.value = window.innerWidth;
}

async function logout(): Promise<void> {
  if (await erpnext.logout()) {
    location.reload();
  }
  await router.replace("/login");
}

function toggleDropdown() {
  menuHidden.value = !menuHidden.value;
}
</script>

<style scoped>
nav {
  overflow: hidden;
  background-color: var(--dark);
  border-radius: 4px;
  border: 1px solid var(--cardBorderBolor);
  position: relative;
  margin-bottom: 2rem;
}

img {
  float: left;
  display: block;
  height: 2rem;
  margin-top: -4px;
}

a:first-child {
  height: 3rem;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
  text-align: center;
}

nav a {
  color: var(--white);
  padding: 14px 16px;
  min-width: 135px;
  text-decoration: none;
  font-size: 14px;
  display: block;
}

nav a.icon {
  background: var(--dark);
  position: absolute;
  right: 0;
  top: 0;
}

nav a:hover {
  background-color: var(--primary);
  color: white;
}

nav .pep-logo-hover:hover {
  background-color: var(--pepLogoHover);
}

nav a:last-child {
  min-width: fit-content;
}

@media (min-width: 1229px) {
  nav {
    overflow: hidden;
    background-color: var(--dark);
  }

  nav a {
    float: left;
    color: var(--white);
    text-align: center;
    padding: auto 16px;
    text-decoration: none;
    font-size: 14px;
  }

  nav a:hover {
    background-color: var(--primary);
    color: black;
  }

  nav .pep-logo-hover:hover {
    background-color: var(--pepLogoHover);
  }

  a.icon {
    display: none;
  }

  .logoutButton {
    display: flex;
    float: right;
  }
}
</style>
