import { DeepReadonly, InjectionKey, Ref } from "vue";
import { BookingDay, erpNextUserProfile, HolidayRequest, WorkplaceBooking, YearReport } from "@/data-types";
import { Validation } from "@vuelidate/core";

export const validate = Symbol() as InjectionKey<Ref<Validation>>;
export const loading = Symbol() as InjectionKey<Ref<boolean>>;
export const profile = Symbol() as InjectionKey<Ref<erpNextUserProfile>>;
export const yearReportKey = Symbol() as InjectionKey<{
  yearReport: Ref<DeepReadonly<YearReport>>;
  addBookingPosition: (sum: any) => void;
}>;
// keys.ts
export const annualStatementKey = Symbol("annualStatement");

export const holidayKey = Symbol() as InjectionKey<Ref<HolidayRequest>>;
export const date = Symbol() as InjectionKey<Ref<Date>>;
export const workplace = Symbol() as InjectionKey<Ref<WorkplaceBooking[]>>;
export const todaysBookingValues = Symbol() as InjectionKey<Ref<BookingDay[]>>;
export const week = Symbol() as InjectionKey<Ref<number>>;
export const dialog = Symbol() as InjectionKey<Ref<boolean>>;
