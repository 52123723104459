<template>
  <div class="card container">
    <slot />
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.card {
  border-radius: 3px;
  padding: 1rem 0.75rem;
  border: 1px solid var(--cardBorderBolor);
  background-color: var(--dark);
  min-height: auto;
}
</style>
