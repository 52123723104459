import axios, { AxiosError, AxiosResponse } from "axios";

axios.defaults.withCredentials = true;
let globalApiUrl = "";
let isProduction = false;

/* The availableApiTargets are only relevant for non-prod frontends. You can either log in
 * to a test backend or a prod backend. The test backend with the url "/apitest/method/" will
 * be re-routed to "/api/method/" in the vite.config.ts (proxy-config). It is used to distinguish
 * between test backend and prod backend. */
const getAvailableApiTargets = () => [
  {
    name: "test",
    text: "erp (Test)",
    url: "/apitest/method/",
  },
  {
    name: "prod",
    text: "erp (Prod)",
    url: "/api/method/",
  },
];

if (import.meta.env.PROD) {
  isProduction = true;
  globalApiUrl = "https://erp.pep-digital.de/api/method/";
} else {
  if (localStorage.apiTarget) {
    const target = getAvailableApiTargets().find((x) => x.name === localStorage.apiTarget);
    if (target) globalApiUrl = target.url;
  }
}

function handleAxiosError(error: AxiosError) {
  // https://axios-http.com/docs/handling_errors
  if (error.response) {
    switch (error.response.status) {
      case 400:
      case 403:
        if (!window.location.pathname.startsWith("/login")) {
          window.location.replace("/login");
        }
        break;
      default:
        break;
    }
  }
}

const postRequest = async <T = any, R = AxiosResponse<T>, D = any>(
  request: string,
  data: any = null,
  params: any = null,
): Promise<{
  ok: boolean;
  result?: Awaited<R>;
  error?: AxiosError<T, D>;
}> => {
  try {
    // axios will resolve the host domain from the browser!
    const result = await axios.post<T, R, D>(globalApiUrl + request, data, params);
    return { ok: true, result };
  } catch (error: any) {
    handleAxiosError(error);
    return Promise.resolve({ ok: false, error });
  }
};

const getRequest = (request: string, params: any = null): Promise<any> => {
  return axios
    .get(globalApiUrl + request, { params: params })
    .then((result) => Promise.resolve({ ok: true, result }))
    .catch((error: AxiosError) => {
      handleAxiosError(error);
      return Promise.resolve({ ok: false, error });
    });
};

const setApiTarget = (url: string) => {
  if (isProduction) return;
  globalApiUrl = url;
};

export default {
  postRequest,
  getRequest,
  getAvailableApiTargets,
  setApiTarget,
  isProduction,
  globalApiUrl,
};
