<template>
  <div class="p-d-flex p-flex-column p-flex-md-row p-jc-center">
    <div class="p-d-flex p-ai-center p-jc-center">
      <h2>{{ title }}</h2>
    </div>
    <div class="p-d-flex p-ai-center p-jc-center p-pl-0 p-pl-md-5">
      <Button icon="pi pi-angle-double-left" class="primaryButton" style="width: 4rem" :disabled="disablePreviousYear" @click="onChangeYear(-1)" />
      <Calendar
        id="calendarDate"
        v-model="currentDate"
        class="p-mx-3 myCalendar"
        view="year"
        input-class="p-text-center"
        date-format="yy"
        :manual-input="false"
      />
      <Button icon="pi pi-angle-double-right" class="primaryButton" style="width: 4rem" :disabled="disableNextYear" @click="onChangeYear(1)" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject, ref } from "vue";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import { date } from "@/keys";
import { addAmountOfYears } from "@/utils/UiUtils.ts";

interface Props {
  disablePreviousYear: boolean;
  disableNextYear: boolean;
  title: string;
}

defineProps<Props>();
const currentDate = inject(date, ref(new Date()));

function onChangeYear(amount: number) {
  currentDate.value = addAmountOfYears(currentDate.value, amount);
}
</script>

<style scoped></style>
