<template>
  <SideBySideCard class="p-d-flex">
    <template #left>
      <AbsenceOverview :new-requests-submitted="newRequestSubmitted" @holiday-deleted="submittedRequest" />
    </template>
    <template #right>
      <HolidayForm :employee="myself" @submitted="submittedRequest" />
    </template>
  </SideBySideCard>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import SideBySideCard from "@/ui/SideBySideCard.vue";
import AbsenceOverview from "@/features/absence/components/absence/AbsenceOverview.vue";
import { useStore } from "vuex";
import { Employee } from "@/data-types";
import HolidayForm from "@/features/absence/components/holiday/HolidayForm.vue";

export default defineComponent({
  name: "HolidayAndIllnessAbsence",
  components: { SideBySideCard, HolidayForm, AbsenceOverview },
  setup() {
    const store = useStore();
    const myself = computed((): Employee => store.getters.getEmployee);
    const newRequestSubmitted = ref(false);

    function submittedRequest() {
      newRequestSubmitted.value = !newRequestSubmitted.value;
    }

    return {
      myself,
      submittedRequest,
      newRequestSubmitted,
    };
  },
});
</script>
